import React from 'react'
import SideBar from '../component/SideBar'
import { image } from '../../../assets/image'
import { useDispatch, useSelector } from 'react-redux'
import { get_live_orders, Init } from '../../../store/actions/merchant'
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
export default function LiveOrders() {
    const [order, setOrder] = React.useState([])
    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch()
    const merchant = useSelector(state => state.Reducers.merchant);

    React.useEffect(() => {
        dispatch(Init(setLoading))
        dispatch(get_live_orders(setOrder, setLoading))
    }, [dispatch])
    return (
        <div className="bg-background min-h-screen flex">
            <SideBar />
            <main className="flex-1 h-screen overflow-y-scroll flex flex-col p-4 space-y-6">
                <div className="flex flex-col sticky top-0 z-50  justify-center items-end">
                    <h1 className="flex items-center text-xl font-body font-medium">
                        <img src={image.logo} alt="logo" className="h-[30px] mr-3" />
                        {merchant?.rest_name}
                    </h1>
                    <p className=' text-[12px] text-gray-700'>{merchant?.name}</p>
                </div>
                <div className=" w-full self-center">
                    <div className="">
                        <h1 className=' font-body text-3xl font-medium'>
                            Live Orders
                        </h1>
                        <p className=' font-body font-normal text-sm text-gray-500'>Track your restaurant's orders in real-time.</p>
                    </div>
                    <table className="w-full text-sm text-left text-gray-500 font-body mt-5">
                        <thead className="text-xs text-gray-700 capitalize font-normal bg-gray-100">
                            <tr className=' font-normal'>
                                <th className="py-2 px-4 font-medium">ID</th>
                                <th className="py-2 px-4 font-medium">Table ID</th>
                                <th className="py-2 px-4 font-medium">Order Details</th>
                                <th className="py-2 px-4 font-medium">Order Time</th>
                                <th className="py-2 px-4 font-medium">Order Status</th>
                                <th className="py-2 px-4 font-medium">Price</th>
                                <th className="py-2 px-4 font-medium">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                [...order]?.reverse()?.map((item, index) => (
                                    <tr key={index} className='  font-light odd:bg-white lowercase even:bg-gray-100' >
                                        <th className="py-2 px-4 font-light">{index + 1}</th>
                                        <th className="py-2 px-4 font-light">Table no : {item?.table_id}</th>
                                        <th className="py-2 px-4 font-light">
                                            {item?.items?.map((subItem, index) => (
                                                <span key={index}>{subItem.trim()}<br /></span>
                                            ))}
                                        </th>
                                        <th className="py-2 px-4 font-light">
                                            {new Date(item?.start_time).toLocaleString()}
                                        </th>
                                        <th className="py-2 px-4 font-light text-left">
                                            <span className={`inline-block px-2 py-1 rounded-full ${item?.status ? 'bg-yellow-800 text-white' : 'bg-green-800 text-white'}`}>
                                                {item?.status ? "Active" : "Completed"}
                                            </span>
                                        </th>
                                        <th className="py-2 px-4 font-light">
                                            {item?.total_price}
                                        </th>
                                        <td className=" space-x-2">
                                            <button className=" text-white font-medium ">
                                                <img src={image.edit} className='h-6 w-6' />
                                            </button>
                                            <button className=" text-white font-medium ">
                                                <img src={image.trash} className='h-6 w-6' />
                                            </button>
                                        </td>
                                    </tr>

                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </main>
        </div>
    )
}
