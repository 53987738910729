import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { TypeAnimation } from 'react-type-animation';
import Header from '../../component/Header';
import Footer from '../../component/Footer';

export default function TermsAndConditions() {

    return (
        <>
            {/* SEO Optimized Head Tags */}
            <Helmet>
                <title>Terms and Conditions | Saturday App</title>
                <meta
                    name="description"
                    content="Read the terms and conditions for using the Saturday App, including user rights, obligations, and limitations. Understand the rules and guidelines for accessing our services."
                />
                {/* <link id="favicon" rel="icon" href={image.google} type="image/x-icon" /> */}
                <meta name="keywords" content="Terms and Conditions, Saturday App, User Agreement, Service Terms" />
                <meta name="author" content="Saturday App" />
                <meta property="og:title" content="Terms and Conditions | Saturday App" />
                <meta
                    property="og:description"
                    content="Read the terms and conditions for using the Saturday App, including user rights, obligations, and limitations. Understand the rules and guidelines for accessing our services."
                />
                <meta property="og:url" content="https://www.saturdayapp.in/terms-and-conditions" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header screen={true} />
            {/* Terms and Conditions Page Content */}
            <main className="flex flex-col justify-start items-center py-10 font-body text-black h-auto w-screen gap-20">
                <section className="text-center pt-10">
                    {/* Animated Heading */}
                    <TypeAnimation
                        sequence={["Terms and Conditions", 1000]}
                        speed={50}
                        cursor={false}
                        style={{ fontSize: '50px', fontWeight: 'normal' }}
                        repeat={Infinity}
                    />
                </section>

                <section className="w-[88%] md:w-[50vw] flex flex-col gap-5 text-lg font-normal text-gray-800">
                    <p className="font-semibold">Last Updated: 17 December 2024</p>

                    <p>
                        These Terms and Conditions (“Terms”) govern your use of the Saturday App website and services. By using the Saturday App, you agree to comply with and be bound by these Terms. Please read them carefully.
                    </p>

                    {/* Section 1: Acceptance of Terms */}
                    <h2 className="text-2xl font-semibold mt-4">1. Acceptance of Terms</h2>
                    <p>
                        By accessing or using the Saturday App website, services, and products, you agree to comply with and be bound by these Terms and any applicable laws and regulations.
                    </p>

                    {/* Section 2: User Responsibilities */}
                    <h2 className="text-2xl font-semibold mt-4">2. User Responsibilities</h2>
                    <ul className="list-disc pl-5">
                        <li>You must provide accurate and complete information when creating an account or using our services.</li>
                        <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
                        <li>You agree to use the services only for lawful purposes and in accordance with applicable laws.</li>
                    </ul>

                    {/* Section 3: Service Availability */}
                    <h2 className="text-2xl font-semibold mt-4">3. Service Availability</h2>
                    <p>
                        Saturday App strives to provide uninterrupted service, but we do not guarantee that the services will always be available without error. Service interruptions may occur due to maintenance, server outages, or unforeseen circumstances.
                    </p>

                    {/* Section 4: Payment Terms */}
                    <h2 className="text-2xl font-semibold mt-4">4. Payment Terms</h2>
                    <p>
                        By using our services, you agree to pay all applicable fees associated with your use of the services. All payments will be processed through a secure payment gateway.
                    </p>

                    {/* Section 5: Data Protection */}
                    <h2 className="text-2xl font-semibold mt-4">5. Data Protection</h2>
                    <p>
                        Your personal data is protected in accordance with our Privacy Policy. By using the services, you consent to the collection, use, and processing of your data as described in our Privacy Policy.
                    </p>

                    {/* Section 6: Restrictions on Use */}
                    <h2 className="text-2xl font-semibold mt-4">6. Restrictions on Use</h2>
                    <ul className="list-disc pl-5">
                        <li>You may not use the services for any illegal or unauthorized purposes.</li>
                        <li>You may not attempt to interfere with the operation of the services or access the services through unauthorized means.</li>
                    </ul>

                    {/* Section 7: Termination of Account */}
                    <h2 className="text-2xl font-semibold mt-4">7. Termination of Account</h2>
                    <p>
                        We may suspend or terminate your access to the services if we believe you have violated these Terms. You may also terminate your account at any time by following the account closure procedure.
                    </p>

                    {/* Section 8: Limitation of Liability */}
                    <h2 className="text-2xl font-semibold mt-4">8. Limitation of Liability</h2>
                    <p>
                        Saturday App will not be liable for any damages, losses, or claims arising from your use or inability to use our services, including but not limited to loss of data, profits, or business opportunities.
                    </p>

                    {/* Section 9: Governing Law */}
                    <h2 className="text-2xl font-semibold mt-4">9. Governing Law</h2>
                    <p>
                        These Terms will be governed by and construed in accordance with the laws of India. Any disputes arising under or in connection with these Terms will be subject to the exclusive jurisdiction of the courts in Nashik.
                    </p>

                    {/* Section 10: Changes to Terms */}
                    <h2 className="text-2xl font-semibold mt-4">10. Changes to Terms</h2>
                    <p>
                        We may update these Terms from time to time. Any changes will be posted on this page, and your continued use of the services after such changes constitutes your acceptance of the revised Terms.
                    </p>

                    {/* Section 11: Contact Us */}
                    <h2 className="text-2xl font-semibold mt-4">11. Contact Us</h2>
                    <p>If you have questions or concerns about our Terms and Conditions, contact us:</p>
                    <ul className="list-none pl-0">
                        <li>Email: <a href="mailto:support@saturdayapp.in" className="text-blue-600">support@saturdayapp.in</a></li>
                        <li>Phone: <a href="tel:02534508891" className="text-blue-600">02534508891</a></li>
                        <li>Address: KBT Circle, Office No. 8, Mezzanine 1st Floor, Jagdish Sankul, Nashik, Maharashtra 422005</li>
                    </ul>

                    <p>By using our services, you agree to these Terms and Conditions.</p>
                </section>
            </main>
            <Footer screen={true} />
        </>
    );
}
