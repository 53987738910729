import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { HiMenuAlt3, HiOutlineX, HiSearch } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { image } from '../../../assets/image';
import { useSelector } from 'react-redux';
import {
    AiFillFacebook,
    AiFillInstagram,
    AiFillTwitterCircle,
    AiOutlineMail
} from 'react-icons/ai'
import { TbWorldWww } from 'react-icons/tb'
import { FaChevronRight } from 'react-icons/fa6'
export default function CustomerHeader({ screen = false }) {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const data = useSelector((state) => state.Reducers.restaurant_data)


    const navigate = useNavigate();

    const handleDropdownToggle = () => {
        setDropdownOpen(prev => !prev);
    };
    const menuVariants = {
        open: {
            x: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 150,
                damping: 20,
            },
        },
        closed: {
            x: '100%',
            opacity: 0,
            transition: {
                type: 'spring',
                stiffness: 150,
                damping: 20,
            },
        },
    };

    return (
        <header className={`fixed top-0 left-0 w-full px-6 py-3 transition-all font-body ${screen ? "bg-white" : "bg-black "} flex items-center justify-between z-50`}>
            {/* Logo on the Left */}
            <h1
                onClick={() => navigate('/')}
                className={`${screen ? 'text-black' : 'text-white'} flex items-center cursor-pointer text-2xl sm:text-3xl md:text-4xl font-body font-light`}
            >
                <img src={image.logo} className="h-[40px] pr-3" alt="logo" />
                Menu
            </h1>

            {/* Hamburger Icon for Mobile */}
            <div className=" space-x-5">
                <button
                    onClick={() => {
                        navigate('/search')
                    }}
                    className={`lg:hidden ${screen ? 'text-black' : 'text-white'} text-[27px] focus:outline-none`}
                >
                    <HiSearch />
                </button>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className={`lg:hidden ${screen ? 'text-black' : 'text-white'} text-3xl focus:outline-none`}
                >
                    <HiMenuAlt3 />
                </button>
            </div>


            {/* Full-Screen Sliding Menu for Mobile */}
            <motion.div
                initial="closed"
                animate={isOpen ? 'open' : 'closed'}
                variants={menuVariants}
                className={`fixed inset-0 h-screen p-4 ${screen ? 'bg-white' : 'bg-black'} backdrop-blur-lg flex flex-col items-center space-y-10 lg:hidden z-40`}
            >
                {/* Top Bar with Logo and Close Icon */}
                <div className="flex w-full justify-between px-2 items-center">
                    <img src={image.logo} className="h-[40px] pr-3" alt="logo" />
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className={`lg:hidden ${screen ? 'text-black' : 'text-white'} text-3xl focus:outline-none`}
                    >
                        <HiOutlineX />
                    </button>
                </div>

                {/* Links */}
                <div className="w-full flex-col flex items-center space-y-3 px-2">
                    <img
                        src={data?.image}
                        alt="icon"
                        className="h-[100px] w-[100px] rounded-full relative  object-contain shadow-md self-center"
                    />
                    <p className="font-body text-black font-bold text-base text-center">
                        {data?.name}
                    </p>
                    <div className="flex space-x-3">
                        <AiFillFacebook size={30} onClick={() => {
                            window.open(data?.restaurant_socials?.FACEBOOK, '_blank')
                        }} />
                        <AiFillInstagram size={30}
                            onClick={() => {
                                window.open(data?.restaurant_socials?.INSTAGRAM, '_blank')
                            }}
                        />
                        <AiFillTwitterCircle size={30}
                            onClick={() => {
                                window.open(data?.restaurant_socials?.TWITTER, '_blank')
                            }}
                        />
                    </div>

                </div>
                <div className="w-full space-y-3">
                    <div className="flex justify-between items-center w-full">
                        <div className="flex space-x-4 items-center">
                            <TbWorldWww size={20} />
                            <p className="text-sm self-center uppercase text-black font-body">
                                Visit our website
                            </p>
                        </div>
                        <FaChevronRight />
                    </div>
                    <div className="flex justify-between items-center w-full">
                        <div className="flex space-x-4 items-center">
                            <AiOutlineMail size={20} />
                            <p className="text-sm self-center uppercase text-black font-body">
                                receive the latest news from <br />
                                {data?.name}
                            </p>
                        </div>
                        <FaChevronRight />
                    </div>
                </div>


                {/* Login and Get Demo Buttons for Mobile */}
                <div className="w-full flex flex-row items-center space-x-5 mt-8">
                    <button
                        onClick={() => navigate('/login')}
                        className={`w-3/12 px-4 py-3 bg-transparent border font-body ${screen ? 'text-black' : 'text-white'} font-semibold rounded-full hover:bg-white hover:text-black transition`}
                    >
                        Login
                    </button>
                    <button
                        onClick={() => navigate("/register")}
                        className={`w-3/6 px-4 py-3 ${screen ? 'text-white bg-black' : 'text-black bg-white'} font-semibold font-body rounded-full hover:bg-logo hover:text-white transition`}
                    >
                        Register
                    </button>
                </div>

                <p className={`font-body font-medium text-center fixed bottom-36 text-base md:text-xl ${screen ? 'text-black' : 'text-white'}`}>
                    Scan it, eat it, love it!
                </p>
                <h1 className={`${screen ? 'text-black' : 'text-white'} fixed bottom-24 text-xl text-center flex items-center font-body`}>
                    <img src={image.logo} className="h-[40px] pr-3" alt="logo" />
                    Saturday
                </h1>
            </motion.div>

            {/* Overlay to close menu when clicking outside */}
            {isOpen && (
                <div
                    onClick={() => setIsOpen(false)}
                    className="fixed inset-0 bg-black opacity-50 z-30 lg:hidden"
                ></div>
            )}
        </header>
    );
}
