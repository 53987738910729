import React from 'react'
import SideBar from '../component/SideBar'
import { image } from '../../../assets/image'

export default function Table() {
    return (
        <div className="bg-background min-h-screen flex">
            <SideBar />
            <main className="flex-1 h-screen overflow-y-scroll flex flex-col p-4 space-y-6">
                <div className=" w-full  flex justify-end items-center">
                    <h1
                        className={`flex items-center self-start  cursor-pointer text-xl sm:text-xl md:text-xl font-body font-medium`}
                    >
                        <img src={image.logo} className="h-[30px] pr-3" alt="logo" />
                        admin
                    </h1>
                </div>
                <div className=" w-full self-center">
                    <div className="">
                        <h1 className=' font-body text-3xl font-medium'>
                            Table Management
                        </h1>
                        <p className=' font-body font-normal text-sm text-gray-500'>Seamlessly manage and organize your restaurant's table arrangements.</p>
                    </div>
                </div>
            </main>
        </div>
    )
}
