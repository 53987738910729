import React from 'react';
import { useLocation } from 'react-router-dom';
import Lottie from "lottie-react";
import { animation } from '../../assets/animation';
import { image } from '../../assets/image';
import { useDispatch } from 'react-redux';
import { endSession } from '../../store/actions/sessionAction';

export default function Done() {
    const { state } = useLocation();
    const dispatch = useDispatch();

    return (
        <div className="flex flex-col items-center justify-center font-body min-h-screen bg-gray-50">
            {/* Checkmark Icon */}
            <Lottie animationData={animation.done} loop={true} className="h-[200px]" />

            {/* Title */}
            <h2 className="text-2xl font-bold text-gray-800 mb-2">Payment Complete</h2>

            {/* Description */}
            <p className="text-gray-600 text-center mb-4">
                Your payment of <span className="font-semibold">{state?.amount || "N/A"}</span> was approved.
            </p>

            {/* Transaction ID */}
            <p className="text-gray-500 text-sm mb-6">
                Transaction ID: <span>{state?.transaction_id || "Unavailable"}</span>
            </p>

            <div className="flex space-x-2">
                <button
                    onClick={() => {
                        dispatch(endSession());
                    }}
                    className="px-6 py-3 bg-blue-600 lowercase text-white font-normal rounded-full shadow-md hover:bg-blue-700 transition"
                >
                    Done
                </button>
                <button
                    onClick={() => window.open("https://www.google.com", "_blank")}
                    className="px-6 py-3 bg-logo lowercase text-white font-normal rounded-full shadow-md hover:bg-blue-700 transition"
                >
                    Add Review
                </button>
            </div>

            {/* Branding */}
            <h1 className="text-black fixed bottom-0 flex items-center cursor-pointer text-2xl sm:text-3xl md:text-4xl font-body font-light">
                <img src={image.logo} className="h-[40px] pr-3" alt="Saturday Logo" />
                Saturday
            </h1>
        </div>
    );
}
