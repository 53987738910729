import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from '@material-tailwind/react';
import { useDispatch } from 'react-redux';
import { get_kitchen_data } from '../../../store/actions/merchant';

const Kitchen = () => {
    const [activeTab, setActiveTab] = useState('tablewise');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const dispatch = useDispatch()
    const tabData = [
        {
            id: 1,
            name: 'Table Wise',
            label: 'tablewise',
            description: 'Table wise, we have a variety of dishes to choose from. Our menu includes a range of cuisines, from classic comfort food to international flavors.',
        },
        // {
        //     id: 2,
        //     name: 'Item Wise',
        //     label: 'itemwise',
        //     description: 'Item wise, we provide accurate dish breakdowns and counts, helping the kitchen staff manage resources efficiently.',
        // },
    ];

    React.useState(() => {
        dispatch(get_kitchen_data(setData, setLoading))
    }, [dispatch])

    return (
        <div className="bg-gradient-to-b from-white via-white to-logo/20 min-h-screen w-screen font-body flex justify-start flex-col items-center p-6">
            {/* Heading Animation */}
            <section className="text-center mb-6">
                <TypeAnimation
                    sequence={["Chai Tapri Kitchen", 1000]}
                    speed={50}
                    cursor={false}
                    style={{ fontSize: '50px', fontWeight: 'normal' }}
                    repeat={Infinity}
                />
            </section>

            {/* Tabs Section */}
            <section className="text-center mb-6 w-[88%]">
                <Tabs value={activeTab}>
                    {/* Tab Headers */}
                    <TabsHeader
                        className="rounded-none  border-b mt-4 z-20 border-blue-gray-50 bg-transparent  p-0 overflow-x-scroll scrollbar-none"
                        indicatorProps={{
                            className: "bg-white border-gray-900 rounded-none",
                        }}
                    >
                        {tabData.map(({ id, label, name }) => (
                            <Tab
                                key={id}
                                value={label}
                                onClick={() => setActiveTab(label)}
                                className={`font-body w-auto text-base font-medium whitespace-nowrap transition-all duration-300 ${activeTab === label
                                    ? "text-gray-800 border-b-[2px] border-logo"
                                    : "text-gray-700 hover:text-gray-900"
                                    }`}
                            >
                                {name}
                            </Tab>
                        ))}
                    </TabsHeader>

                    {/* Tab Content */}
                    <TabsBody>
                        {tabData.map(({ id, label, description }) => (
                            <TabPanel key={id} value={label} className="pt-16">
                                <div className="text-gray-700 text-lg font-body text-center p-4 flex flex-wrap space-x-3">
                                    {
                                        data?.map((item, index) => (
                                            <div
                                                key={index}
                                                className="w-[300px] bg-white border rounded-lg shadow-md p-4 text-gray-800"
                                            >
                                                <p className="text-lg font-semibold font-body mb-2">
                                                    Table Number: {item?.table_number}
                                                </p>
                                                <div className=" p-3 rounded-lg text-start">
                                                    {item?.order_items &&
                                                        Object.entries(item.order_items).map(([key, value], index) => (
                                                            <div key={index} className="mb-4">
                                                                <p className="text-sm font-medium">{key} X {value.quantity}</p>
                                                                {value?.customization && (
                                                                    <p className="text-sm text-gray-600">
                                                                        <span className="font-semibold">Customization:</span>{" "}
                                                                        {value.customization || "None"}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        ))}
                                                </div>
                                                <button className=' bg-green-600 self-end text-sm py-1 px-3 font-body text-white'>
                                                    Completed
                                                </button>
                                            </div>
                                        ))
                                    }
                                </div>
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </section>
        </div>
    );
};

export default Kitchen;
