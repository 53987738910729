import React from 'react';
import { image } from '../../../assets/image';
import { useLocation } from 'react-router-dom';

export default function SideBar() {
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    const navItems = [
        { path: '/merchant/dashboard', label: 'Dashboard', icon: image.dashboard },
        { path: '/merchant/live-orders', label: 'Live Order', icon: image.activity },
        { path: '/merchant/menu-management', label: 'Menu Management', icon: image.cup },
        { path: '/merchant/table-management', label: 'Table Management', icon: image.board },
        { path: '/merchant/restaurant-details', label: 'Restaurant Details', icon: image.plate },
        { path: '/merchant/employees', label: 'Employees', icon: image.users },
        { path: '/merchant/settings', label: 'Settings', icon: image.setting },
    ];

    return (
        <div className="w-[20%] h-screen bg-white border-r flex flex-col items-center">
            <h1 className="flex items-center self-start p-3 cursor-pointer text-2xl sm:text-3xl md:text-3xl font-body font-light">
                <img src={image.logo} className="h-[40px] pr-3" alt="logo" />
                Saturday
            </h1>
            <div className="space-y-2 w-[88%] mt-4 self-center">
                {navItems.map((item) => (
                    <a
                        key={item.path}
                        href={item.path}
                        className={`flex font-body py-1 font-light items-center ${isActive(item.path)
                            ? 'text-logo font-medium'
                            : 'hover:text-logo hover:font-normal text-gray-600'
                            }`}
                    >
                        <img
                            src={item.icon}
                            className="w-[22px] h-[22px] mr-2"
                            alt={item.label}
                        />
                        <span className="text-lg ">{item.label}</span>
                    </a>
                ))}
                <hr />
                <a
                    key={"/merchant/kitchen"}
                    href={"/merchant/kitchen"}
                    className={`flex font-body py-1 font-light items-center `}
                >
                    <img
                        src={image.switch}
                        className="w-[22px] h-[22px] mr-2"
                        alt={"Switch to Kitchen Mode"}
                    />
                    <span className="text-base lowercase ">{"Switch to Kitchen Mode"}</span>
                </a>
            </div>
        </div>
    );
}
