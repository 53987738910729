import React from 'react'
import { image } from '../../../assets/image'
import Header from '../../../component/Header'
import { useDispatch } from 'react-redux'
import { loginAction } from '../../../store/actions/merchant'
import { TailSpin } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'

export default function Login() {
    const [data, setData] = React.useState({
        "email": "",
        "password": ""
    })
    const [loading, setLoading] = React.useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <div className="min-h-screen w-full flex justify-center items-center bg-black font-body p-4">
                <div className="w-full max-w-sm md:max-w-md h-[550px] md:h-[600px] bg-white flex flex-col justify-center items-center p-6 md:p-10 space-y-6 font-body rounded shadow-lg">
                    <div className="flex justify-center items-center space-x-3">
                        <img
                            src={image.logo}
                            alt="Logo"
                            className="h-12 w-12 md:h-14 md:w-14"
                        />
                        <p className="font-body text-3xl md:text-4xl ">
                            Saturday
                        </p>
                    </div>
                    <h1 className="text-xl md:text-3xl tracking-wider font-semibold">Welcome</h1>
                    <p className="text-sm md:text-base text-gray-700 text-center">
                        Login to Saturday to continue to the Merchant dashboard
                    </p>
                    <div className="w-full space-y-1 font-body">
                        <label htmlFor="email" className="text-sm lowercase md:text-base">
                            Email address <span className='text-red-500'>*</span>
                        </label>
                        <input
                            type="text"
                            id="email"
                            value={data.email}
                            onChange={(e) => {
                                setData({ ...data, email: e.target.value })
                            }}
                            className="h-12 w-full border-[1.5px] border-gray-200 bg-white px-2 outline-none rounded hover:border-2 hover:border-logo font-body focus:border-logo transition"
                            placeholder="Email address*"
                        />
                    </div>
                    <div className="w-full space-y-1">
                        <label htmlFor="password" className="text-sm lowercase md:text-base">
                            Password <span className='text-red-500'>*</span>
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={data.password}
                            onChange={(e) => {
                                setData({ ...data, password: e.target.value })
                            }}
                            className="h-12 w-full border-[1.5px] border-gray-200 bg-white px-2 outline-none rounded hover:border-2 hover:border-logo font-body focus:border-logo transition"
                            placeholder="Password*"
                        />

                    </div>
                    <a
                        href="/merchant/reset-password"
                        className="text-text-dark text-sm md:text-base font-semibold text-left w-full"
                    >
                        Forgot Password?
                    </a>
                    <button
                        type="button"
                        onClick={() => {
                            dispatch(loginAction(data, setLoading, navigate))
                        }}
                        className="bg-black h-12 w-full text-white flex justify-center items-center text-sm md:text-base rounded  transition"
                    >
                        {
                            loading ?
                                <TailSpin
                                    visible={true}
                                    height="=20"
                                    width="20"
                                    // radius="9"
                                    color="#fff"
                                    ariaLabel="three-dots-loading"
                                />
                                :
                                "login"
                        }
                    </button>
                </div>
            </div>
        </>
    )
}
