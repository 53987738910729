import React from 'react';
import Header from '../../component/Header';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export default function Notfound() {
    const navigate = useNavigate()
    return (
        <>
            {/* Section 1 */}
            <Header screen={true} />
            <div className="min-h-screen w-screen font-body bg-white flex flex-col lg:flex-row justify-around items-center px-4 md:px-0">
                {/* Text Section */}
                <div className="space-y-5 w-full mt-16 md:mt-0  sm:w-[40%] lg:w-[40%] px-4">
                    <motion.h1
                        className="text-5xl font-semibold text-left lg:text-left font-body text-black mt-8 md:mt-10"
                        initial={{ x: 1000, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 10,
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.5,
                        }}
                    >
                        Uh oh, looks like your ordered the wrong thing.
                    </motion.h1>
                    <p className='font-body font-normal text-base md:text-xl text-gray-500'>
                        Head back to the menu and try something else.
                    </p>
                    <button
                        type="button"
                        className="relative h-10 w-40 sm:h-14 sm:w-48 lg:h-16 lg:w-56 font-medium rounded-full border border-black overflow-hidden group"
                        onClick={() => {
                            navigate('/')
                        }}
                    >

                        {/* Animated Background */}
                        <span className="absolute inset-0 bg-logo transform scale-x-0 origin-left transition-transform duration-500 group-hover:scale-x-100"></span>

                        {/* Button Content */}
                        <span className="relative z-10 text-black group-hover:text-white transition-colors duration-300">
                            Go to Homepage
                        </span>
                    </button>
                </div>

                {/* Video Section */}
                <div className="w-full sm:w-[40%] lg:w-[40%] flex justify-center sm:px-4 md:px-0">
                    <video className="rotate-6 w-full  max-w-lg" autoPlay muted loop src="https://sundayapp.com/app/uploads/2023/02/404.mp4"></video>
                </div>
            </div>
        </>
    );
}
