import React, { useState, useEffect } from 'react';
import SideBar from '../component/SideBar';
import { image } from '../../../assets/image';
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import { get_orders_and_revenue, get_tables_and_menu, get_todays_orders_and_revenue, Init } from '../../../store/actions/merchant';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Dashboard() {
    const [isOpen, setIsOpen] = useState(false);
    const [dashData, setDashData] = useState(null);
    const [salecount, setSalescount] = useState(null);
    const [todaysale, setTodaySales] = useState(null);
    const [sale, setSale] = React.useState({})

    const [loading, setLoading] = useState(false);
    const [timeframe, setTimeframe] = useState(12);

    const dispatch = useDispatch()
    const merchant = useSelector(state => state.Reducers.merchant);

    const [selectedOption, setSelectedOption] = useState('Show Stats: Yearly');
    const optionsList = [
        {
            "name": "Show Stats: Yearly",
            "timeframe": 12
        },
        {
            "name": "Show Stats: last 6 month",
            "timeframe": 6
        },
        {
            "name": "Show Stats: last 3 month",
            "timeframe": 3
        },
        {
            "name": "Show Stats: 1 month",
            "timeframe": 1
        },
    ];

    const handleToggle = () => setIsOpen(!isOpen);
    const handleSelect = (option) => {
        setSelectedOption(option?.name);
        setTimeframe(option?.timeframe)
        dispatch(get_orders_and_revenue(setSalescount, option.timeframe, setLoading))
        setIsOpen(false);
    };

    const data = {
        labels: salecount?.labelList,
        datasets: [
            {
                label: `Revenue (${salecount?.currency})`,
                data: salecount?.revenueDataList,
                backgroundColor: '#f414e470',
                borderColor: '#f414e4',
                borderWidth: 1,
            },
            {
                label: 'Orders',
                data: salecount?.orderDataList,
                backgroundColor: '#C8C2FC70',
                borderColor: '#C8C2FC',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    font: { family: 'Poppins', size: 12 },
                },
            },
            tooltip: { mode: 'index', intersect: false },
        },
        scales: {
            x: { ticks: { font: { family: 'Poppins', size: 12 } } },
            y: { ticks: { beginAtZero: true, font: { family: 'Poppins', size: 12 } } },
        },
    };

    useEffect(() => {
        dispatch(Init(setLoading));
        dispatch(get_tables_and_menu(setDashData, setLoading));
        dispatch(get_orders_and_revenue(setSalescount, timeframe, setLoading));
        dispatch(get_todays_orders_and_revenue(setTodaySales, setLoading));
    }, [dispatch]);

    const today = {
        labels: ['Sales Today'],
        datasets: [
            {
                label: `Revenue (${salecount?.currency})`,
                data: [todaysale?.total_revenue],
                backgroundColor: '#f414e470',
                borderColor: '#f414e4',
                borderWidth: 1,
            },
            {
                label: 'Orders',
                data: [todaysale?.total_orders],
                backgroundColor: '#C8C2FC70',
                borderColor: '#C8C2FC',
                borderWidth: 1,
            },
        ],
    };

    const MetricCard = ({ title, value }) => (
        <div className="w-full md:w-[25%] flex flex-col items-start px-4 py-6 border-r last:border-none">
            <h1 className="font-body text-lg font-medium">{title}</h1>
            <h1 className="font-body text-3xl font-bold text-logo">{value}</h1>
        </div>
    );

    return (
        <div className="bg-background min-h-screen flex font-body">
            <SideBar />
            <main className="flex-1 h-screen overflow-y-scroll flex flex-col p-4 space-y-6">
                {/* Header */}
                <div className="flex flex-col sticky top-0 z-50  justify-center items-end">
                    <h1 className="flex items-center text-xl font-body font-medium">
                        <img src={image.logo} alt="logo" className="h-[30px] mr-3" />
                        {merchant?.rest_name}
                    </h1>
                    <p className=' text-[12px] text-gray-700'>{merchant?.name}</p>
                </div>

                {/* Welcome Section */}
                <div className="flex flex-col md:flex-row justify-between items-start">
                    <div>
                        <h1 className="font-body text-3xl font-medium">Welcome back, {merchant?.name}</h1>
                        <p className="font-body text-sm text-gray-500">
                            Here's what's happening with your {merchant?.rest_name}
                        </p>
                    </div>
                    {/* Dropdown */}
                    <div className="relative w-72">
                        <button
                            onClick={handleToggle}
                            className="w-full bg-white border rounded-md py-2 px-4 text-left flex justify-between items-center text-gray-700 hover:bg-gray-100"
                        >
                            <span>{selectedOption}</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`h-5 w-5 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                        {isOpen && (
                            <div className="absolute mt-1 w-full bg-white border rounded-md shadow-lg z-10">
                                {optionsList.map((option, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleSelect(option)}
                                        className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {option.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {/* Metrics Section */}
                <div className="flex flex-wrap bg-white shadow-sm">
                    <MetricCard title="Total Orders" value={salecount?.total_orders} />
                    <MetricCard title="Total Revenue" value={salecount?.total_revenue} />
                    <MetricCard title="Menu Items" value={dashData?.menu_item_count} />
                    <MetricCard title="Tables" value={dashData?.table_count} />
                </div>

                {/* Chart Section */}
                <div className="flex w-full space-x-4">
                    {/* Revenue vs Orders Chart */}
                    <div className="bg-white px-6 py-2 shadow-md w-[70%]">
                        <h2 className="text-lg font-medium text-center mb-4">Revenue vs Orders</h2>
                        <Bar data={data} options={options} />
                    </div>

                    {/* Real-Time Sales Chart */}
                    <div className="bg-white px-6 py-2 shadow-md w-[30%] flex flex-col justify-evenly h-full font-body">
                        <h2 className="text-base font-medium text-center my-2">Today's Sale</h2>
                        <Bar data={today} options={options} />
                        <h2 className="text-base font-medium text-center my-2">Today's Best seller</h2>
                        <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                                <tr>
                                    <th className="py-2 px-4">Product</th>
                                    <th className="py-2 px-4">Sold Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bg-white border-b">
                                    <td className="py-2 px-4">Chai</td>
                                    <td className="py-2 px-4">1000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </main>
        </div>
    );
}
