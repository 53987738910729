import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import { useSelector } from 'react-redux';
import { animation } from '../../assets/animation';

export default function EmptyCart() {
    const location = useLocation();
    const navigate = useNavigate();

    // Extract restaurant_id and restaurant_name from query params
    const queryParams = new URLSearchParams(location.search);
    const restaurant_id = queryParams.get('restaurant_id');
    const restaurant_name = queryParams.get('restaurant_name');

    const tid = useSelector((state) => state.Reducers.table_id);

    return (
        <div className="bg-gradient-to-b from-white via-white to-logo/20 min-h-screen w-screen h-screen flex flex-col justify-center items-center  bg-white font-body px-4">
            {/* Animation */}
            <div className="flex flex-col items-center space-y-5">
                <Lottie
                    animationData={animation.cart}
                    loop={true}
                    className="w-[250px] h-[250px] mb-5"
                />

                {/* Main Title */}
                <h1
                    className="text-2xl md:text-3xl bg-blend-overlay font-bold text-gray-800">
                    Your Cart is Empty
                </h1>

                {/* Restaurant-specific Message */}
                {restaurant_name && (
                    <p className="text-md md:text-lg text-center text-gray-600">
                        You haven't added any items <br />
                        from <span className="font-bold text-black">{restaurant_name}</span> yet!
                    </p>
                )}

                {/* Encouragement Text */}
                <p className="text-sm md:text-md text-center text-gray-500">
                    Start adding some delicious meals to your order.
                </p>
            </div>

            {/* Navigation Button */}
            <button
                onClick={() =>
                    navigate(
                        `/menu/?restaurant_id=${restaurant_id}&restaurant_name=${restaurant_name}&table_id=${tid}`
                    )
                }
                className="bg-black h-14 mt-8 w-full max-w-md text-white flex justify-center items-center text-sm md:text-base rounded-full transition duration-300 ease-in-out lowercase hover:bg-gray-800"
            >
                <span>
                    See Menu {restaurant_name ? `for ${restaurant_name}` : ''}
                </span>
            </button>
        </div>
    );
}
