import React, { useState, useEffect } from 'react'
import { HiOutlineX } from 'react-icons/hi';
import { Sheet } from 'react-modal-sheet';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import { image } from '../../../assets/image';
import { motion } from 'framer-motion';
export default function BillType({
    show,
    setShow,
    mode,
    order
}) {
    const navigate = useNavigate()
    const session_id = useSelector(state => state.Reducers?.session_id);
    const table_id = useSelector(state => state.Reducers?.table_id);
    const user_id = useSelector(state => state.Reducers?.user_id);
    const fullPaymentOption = mode[0]?.data[0];
    const splitPaymentOptions = mode[1]?.data;
    const isSplitLocked = mode[1]?.locked;
    const isFullLocked = fullPaymentOption?.locked;

    const [current, setCurrent] = React.useState(0);
    const [currentData, setCurrentData] = React.useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [billMethod, setBillMethod] = useState('');
    const [splitMethod, setSplitMethod] = useState(false);
    const [customAmount, setCustomAmount] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);

    const resetPaymentOptions = () => {
        setBillMethod('');
        setSplitMethod(false);
        setCustomAmount('');
        setSelectedItems([])
        setCurrent(0)
        setIsFullScreen(false); // Reset full screen state
    };

    const toggleSelectItem = (item) => {
        setSelectedItems((prevSelected) => {
            const isSelected = prevSelected.some(selectedItem => selectedItem.id === item.id);
            if (isSelected) {
                return prevSelected.filter(selectedItem => selectedItem.id !== item.id);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    const calculateTotal = (order, user_id) => {
        return order?.reduce((total, item) => {
            return item?.user_id == user_id ? total + item.order_total : total;
        }, 0); // Initial total is set to 0
    };



    // useEffect(() => {
    //     setSelectedMode(isFullLocked ? fullPaymentOption : availableSplitOptions);
    // }, []);

    const handleCustomAmountChange = (e) => {
        setCustomAmount(e.target.value);
    };

    const handleCustomPayment = () => {
        const customAmountNumber = parseFloat(customAmount);
        const totalAmount = fullPaymentOption.amount;

        if (customAmountNumber > 0 && customAmountNumber <= totalAmount) {
            navigate('/payment-method', {
                state: {
                    method: 'custom',
                    amount: customAmountNumber,
                    session_id: session_id,
                    user_id: user_id,
                }
            });
        } else {
            alert('Please enter a valid amount less than or equal to the total.');
        }
    };

    return (
        <Sheet
            isOpen={show}
            onClose={() => setShow(false)}
            detent={isFullScreen ? 'content-height' : 'collapsed'}
            // snapPoints={current === 0 ? [0.30, 0.20, 0] : [0.40, 0.20, 0]}
            initialSnap={0}
        >
            <Sheet.Container className={current === 3 ? 'h-full ease-linear transition-all' : ''}>
                <Sheet.Header />
                <Sheet.Content
                    className={`${current === 3 ? 'h-full ease-linear transition-all' : 'max-h-[350px]'}`}>
                    {
                        current === 0 ?
                            <div className="p-4 font-body space-y-2">
                                <div className="border-b border-gray-300 font-body flex justify-between items-center">
                                    <TypeAnimation
                                        sequence={[
                                            "How would you like to pay?", 1000,   // First text, with a 1-second delay
                                            "What's your payment mood?", 1000,
                                            "Choose Your Payment Style", 1000
                                        ]}
                                        speed={50}  // Typing speed
                                        cursor={false}  // No cursor
                                        style={{ fontSize: '18px', fontWeight: 'normal' }}  // Custom styling
                                        repeat={Infinity}  // Infinite looping of the sequence
                                    />
                                    <button
                                        onClick={() => {
                                            setShow(false)
                                            setCurrent(0)
                                            resetPaymentOptions();
                                        }}
                                    >
                                        <HiOutlineX className=' text-2xl text-gray-500' />
                                    </button>
                                </div>
                                {isFullLocked && (
                                    <button
                                        type="button"
                                        onClick={() => {

                                        }}
                                        className="bg-black h-12 w-full text-white flex lowercase justify-center items-center text-sm md:text-base rounded  transition"
                                    >
                                        pay full bill
                                    </button>
                                )}
                                {isSplitLocked && (
                                    <button
                                        type="button"
                                        onClick={() => {

                                        }}
                                        className="bg-black h-12 w-full text-white flex lowercase justify-center items-center text-sm md:text-base rounded  transition"
                                    >
                                        split the bill
                                    </button>
                                )}
                                {!isSplitLocked && !isFullLocked && (
                                    <>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                navigate("/checkout", {
                                                    state: {
                                                        state: true,
                                                        method: fullPaymentOption?.type,
                                                        amount: fullPaymentOption?.amount,
                                                        currency: order[0]?.currency,
                                                        session_id: session_id,
                                                        user_id: user_id,
                                                    }
                                                })
                                            }}
                                            className="bg-black h-12 w-full text-white flex lowercase justify-center items-center text-sm md:text-base rounded  transition"
                                        >
                                            pay full bill
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setCurrent(2)}
                                            className="bg-black h-12 w-full text-white flex lowercase justify-center items-center text-sm md:text-base rounded  transition"
                                        >
                                            split the bill
                                        </button>
                                    </>
                                )}
                            </div>
                            :
                            null
                    }
                    {
                        current == 2 ?
                            <div className="p-4 font-body space-y-2">
                                <div className="border-b border-gray-300 font-body flex justify-between items-center">
                                    <TypeAnimation
                                        sequence={[
                                            "Select Split method", 1000,   // First text, with a 1-second delay
                                        ]}
                                        speed={50}  // Typing speed
                                        cursor={false}  // No cursor
                                        style={{ fontSize: '18px', fontWeight: 'normal' }}  // Custom styling
                                        repeat={Infinity}  // Infinite looping of the sequence
                                    />
                                    <button
                                        onClick={() => {
                                            setShow(false)
                                            setCurrent(0)
                                            resetPaymentOptions();
                                        }}
                                    >
                                        <HiOutlineX className=' text-2xl text-gray-500' />
                                    </button>
                                </div>
                                {
                                    splitPaymentOptions.map((option, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            onClick={() => {
                                                if (option?.type === "custom") {
                                                    setCurrent(4)
                                                    setCurrentData(option)
                                                }
                                                else if (option?.type === "my_items_only") {
                                                    setCurrent(3)
                                                    setCurrentData(option)
                                                }
                                                else if (option?.type === "split_equally") {
                                                    setCurrent(5)
                                                    setCurrentData(option)
                                                }
                                            }}
                                            className="bg-black h-12 w-full text-white flex lowercase justify-center items-center text-sm md:text-base rounded  transition"
                                        >
                                            {option?.name}
                                        </button>
                                    ))
                                }
                            </div>
                            :
                            null
                    }
                    {
                        current === 3 ?
                            <>

                                <motion.div
                                    initial={{ opacity: 0, y: 100 }} // Start slightly below and invisible
                                    animate={{ opacity: 1, y: 0 }}  // Move up into position and become visible
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    className="p-4 font-body space-y-2">
                                    <div className="border-b border-gray-300 font-body flex justify-between items-center">
                                        <TypeAnimation
                                            sequence={[
                                                "My items only", 1000,   // First text, with a 1-second delay
                                            ]}
                                            speed={50}  // Typing speed
                                            cursor={false}  // No cursor
                                            style={{ fontSize: '18px', fontWeight: 'normal' }}  // Custom styling
                                            repeat={Infinity}  // Infinite looping of the sequence
                                        />
                                        <button
                                            onClick={() => {
                                                setCurrent(2)
                                            }}
                                        >
                                            <HiOutlineX className=' text-2xl text-gray-500' />
                                        </button>
                                    </div>
                                    <div className="flex items-center overflow-y-scroll flex-col">
                                        {
                                            order?.map((item, index) => {
                                                if (item?.user_id == user_id) {
                                                    return (
                                                        <div key={index} className="w-full bg-white items-center mt-1  flex justify-between  py-1">
                                                            <p className=' text-sm w-[70%]  text-start lowercase font-body'>{item?.name}</p>
                                                            <div className="flex w-[30%] space-x-3 items-center justify-end">
                                                                <p className="text-base font-normal font-body">{item?.currency} {item?.order_total}</p>
                                                                {/* <button
                                                        onClick={() => {
                                                            toggleSelectItem(item)
                                                        }}
                                                        className={`h-[25px] ${selectedItems.includes(item) ? "bg-logo" : ""} flex justify-center items-center w-[25px] border rounded-full`}>
                                                        {selectedItems.includes(item) ? (
                                                            <img src={image.tick} className='w-3 text-white h-3' alt="done" />
                                                        ) : (
                                                            <img src={image.plus} className='w-4 h-4' alt="add" />
                                                        )}
                                                    </button> */}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                            )
                                        }
                                    </div>
                                </motion.div>
                                <div className=" flex w-full flex-col justify-evenly items-center bg-black fixed bottom-0 ">
                                    <div className="flex w-full px-3 py-2 text-white lowercase justify-between items-center">
                                        <p className=' text-xl font-body'>Total bill</p>
                                        <p className="font-medium font-body text-xl">{order[0]?.currency} {calculateTotal(order, user_id)}</p>
                                    </div>

                                    <div className="flex w-full h-[60px] items-center justify-evenly py-2 bg-black">
                                        <button
                                            onClick={() => setCurrent(2)}
                                            className="bg-logo text-white font-medium h-[40px] w-44 rounded-lg text-base font-body lowercase outline-none transition-all hover:bg-logo-dark">
                                            <p>Cancle Split</p>
                                        </button>
                                        <button
                                            // onClick={() => navigate('/checkout', {
                                            //     state: {
                                            //         state: true,
                                            //         method: 'my_items_only',
                                            //         amount: calculateTotal(),
                                            //         currency: order[0]?.currency,
                                            //         session_id: session_id,
                                            //         user_id: user_id,
                                            //     }
                                            // })}
                                            onClick={() => {
                                                console.log(calculateTotal(order, user_id))
                                            }}
                                            className="bg-white text-black  font-medium h-[40px] w-44 rounded-lg text-base font-body lowercase outline-none transition-all hover:bg-logo hover:text-white">
                                            <p>Confirm</p>
                                        </button>
                                    </div>


                                </div>
                            </>
                            :
                            null
                    }
                    {
                        current === 4 ?
                            <>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }} // Start slightly below and invisible
                                    animate={{ opacity: 1, y: 0 }}  // Move up into position and become visible
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    className="p-4 font-body space-y-2">
                                    <div className="border-b border-gray-300 font-body flex justify-between items-center">
                                        <TypeAnimation
                                            sequence={[
                                                "Custom Amount", 1000,   // First text, with a 1-second delay
                                            ]}
                                            speed={50}  // Typing speed
                                            cursor={false}  // No cursor
                                            style={{ fontSize: '18px', fontWeight: 'normal' }}  // Custom styling
                                            repeat={Infinity}  // Infinite looping of the sequence
                                        />
                                        <button
                                            onClick={() => {
                                                setCurrent(2)
                                            }}
                                        >
                                            <HiOutlineX className=' text-2xl text-gray-500' />
                                        </button>
                                    </div>
                                    <input
                                        value={customAmount}
                                        onChange={handleCustomAmountChange}
                                        className="h-12 w-full border-[1.5px] border-gray-200 bg-white px-2 outline-none rounded hover:border-2 hover:border-logo font-body focus:border-logo transition"
                                        type={"number"}
                                        placeholder={`Add Custom Amount ${order[0]?.currency}`}
                                    />
                                </motion.div>
                                <div className="flex font-body text-white text-lg w-full justify-center z-20 items-center">
                                    <button
                                        onClick={() => {
                                            navigate("/checkout", {
                                                tate: {
                                                    state: true,
                                                    method: 'custom',
                                                    amount: customAmount,
                                                    currency: order[0]?.currency,
                                                    session_id: session_id,
                                                    user_id: user_id,
                                                }
                                            })
                                        }}
                                        className="bg-black flex justify-between px-4 lowercase items-center  w-[100%] h-[60px] shadow-lg hover:shadow-xl transition"
                                    >
                                        <p>payable amount : {order[0]?.currency} {customAmount}</p>
                                        <p className='px-4 py-1 bg-white text-black'>checkout</p>
                                    </button>
                                </div>
                            </>
                            :
                            null
                    }
                    {
                        current === 5 ?
                            <>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }} // Start slightly below and invisible
                                    animate={{ opacity: 1, y: 0 }}  // Move up into position and become visible
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    className="p-4 font-body space-y-2">
                                    <div className="border-b border-gray-300 font-body flex justify-between items-center">
                                        <TypeAnimation
                                            sequence={[
                                                "Equal split", 1000,   // First text, with a 1-second delay
                                            ]}
                                            speed={50}  // Typing speed
                                            cursor={false}  // No cursor
                                            style={{ fontSize: '18px', fontWeight: 'normal' }}  // Custom styling
                                            repeat={Infinity}  // Infinite looping of the sequence
                                        />
                                        <button
                                            onClick={() => {
                                                setCurrent(2)
                                            }}
                                        >
                                            <HiOutlineX className=' text-2xl text-gray-500' />
                                        </button>
                                    </div>
                                </motion.div>
                                <div className="flex font-body text-white text-lg w-full justify-center z-20 items-center">
                                    <button
                                        onClick={() => {
                                            navigate("/checkout", {
                                                state: {
                                                    state: true,
                                                    method: currentData?.type,
                                                    amount: currentData?.amount,
                                                    currency: order[0]?.currency,
                                                    session_id: session_id,
                                                    user_id: user_id,
                                                }
                                            })
                                        }}
                                        className="bg-black flex justify-between px-4 lowercase items-center  w-[100%] h-[60px] shadow-lg hover:shadow-xl transition"
                                    >
                                        <p>payable amount : {order[0]?.currency} {currentData?.amount}</p>
                                        <p className='px-4 py-1 bg-white text-black'>checkout</p>
                                    </button>
                                </div>
                            </> : null
                    }
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet >
    )
}
