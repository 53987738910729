import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import { TypeAnimation } from 'react-type-animation';

export default function ContactUs() {
    return (
        <>
            {/* SEO Optimized Head Tags */}
            <Helmet>
                <title>Contact Us | Saturday App</title>
                <meta
                    name="description"
                    content="Get in touch with Saturday App for support, inquiries, or feedback. We are here to help."
                />
                <meta name="keywords" content="Contact Us, Saturday App, Support, Customer Service" />
                <meta name="author" content="Saturday App" />
                <meta property="og:title" content="Contact Us | Saturday App" />
                <meta
                    property="og:description"
                    content="Get in touch with Saturday App for support, inquiries, or feedback. We are here to help."
                />
                <meta property="og:url" content="https://www.saturdayapp.in/contact-us" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header screen={true} />
            {/* Contact Us Page Content */}
            <main className="flex flex-col justify-start items-center py-10 font-body text-black h-auto w-screen gap-20">
                <section className="text-center pt-10">
                    <TypeAnimation
                        sequence={["Contact Us", 1000]}
                        speed={50}
                        cursor={false}
                        style={{ fontSize: '50px', fontWeight: 'normal' }}
                        repeat={Infinity}
                    />
                </section>

                <section className="w-[88%] md:w-[50vw] flex flex-col gap-5 text-lg font-normal text-gray-800">
                    <p className="font-semibold">Last Updated: 17 December 2024</p>

                    <p>
                        We value your feedback and are here to assist you with any questions or concerns. Please reach out to us through any of the following methods:
                    </p>

                    <h2 className="text-2xl font-semibold mt-4">1. Email</h2>
                    <p>For any inquiries or support, email us at: <a href="mailto:support@saturdayapp.in" className="text-blue-600">support@saturdayapp.in</a></p>

                    <h2 className="text-2xl font-semibold mt-4">2. Phone</h2>
                    <p>Call us at: <a href="tel:02534508891" className="text-blue-600">02534508891</a></p>

                    <h2 className="text-2xl font-semibold mt-4">3. Address</h2>
                    <p>Visit us at:</p>
                    <ul className="list-none pl-0">
                        <li>KBT Circle, Office No. 8, Mezzanine 1st Floor, Jagdish Sankul, Nashik, Maharashtra 422005</li>
                    </ul>

                    <h2 className="text-2xl font-semibold mt-4">4. Social Media</h2>
                    <p>Follow us on our social media handles for updates and more:</p>
                    <a href='https://www.instagram.com/saturdayapp.in/' className={` font-light text-sm md:text-base`}>Instagram</a>
                    <a href='https://www.facebook.com/saturdayapp.in/' className={` font-light text-sm md:text-base`}>Facebook</a>
                    <a href='https://www.linkedin.com/company/saturdayapp/' className={` font-light text-sm md:text-base`}>LinkedIn</a>
                </section>
            </main>
            <Footer screen={true} />
        </>
    );
}
