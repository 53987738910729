import React from 'react'
import CustomerHeader from './component/CustomerHeader'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMenu, getRestaurant, storeQRData } from '../../store/actions/homeActions';
import TopLoadingBar from 'react-top-loading-bar'
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from '@material-tailwind/react';
import MenuCard from './component/MenuCard'
import { TailSpin } from 'react-loader-spinner';
export default function Menu() {
    const [loading, setLoading] = React.useState(true);
    const [progress, setProgress] = React.useState(0)
    const [activeTab, setActiveTab] = React.useState("");

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.Reducers.cart);
    const menu = useSelector((state) => state.Reducers.menu);

    React.useEffect(() => {
        const params = new URLSearchParams(location?.search);
        dispatch(
            storeQRData(
                params.get('restaurant_id'),
                params.get('table_id')
            )
        )
        dispatch(getRestaurant(params.get('restaurant_id'), setLoading, setProgress));
        dispatch(getMenu(params.get('restaurant_id')));
        setTimeout(() => {
            setLoading(false);
            setActiveTab(menu[0]?.value?.toLowerCase())
        }, 5000);

    }, [dispatch, location?.search]);


    return (
        <>
            {/* Section 1 */}
            <TopLoadingBar
                color="#f414e4"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <CustomerHeader screen={true} />
            {
                loading ?
                    <div className="flex justify-center h-screen w-screen flex-col font-body space-y-4 items-center">
                        <TailSpin
                            visible={true}
                            height="30"
                            width="30"
                            // radius="9"
                            color="#f414e4"
                            ariaLabel="three-dots-loading"
                        />
                        <p className="text-xs lowercase text-logo font-semibold">
                            loading menu
                        </p>
                    </div>
                    :
                    <>
                        <Tabs value={menu[0]?.value?.toLowerCase()}>
                            <TabsHeader
                                className="rounded-none fixed w-screen border-b mt-16 z-20 border-blue-gray-50 bg-white p-0 overflow-x-scroll scrollbar-none scrollbar-thumb-transparent no-scrollbar"
                                indicatorProps={{
                                    className: "bg-white border-gray-900 rounded-none",
                                }}
                            >
                                {menu?.map(({ label, value }) => {
                                    return (
                                        <Tab
                                            key={value.toLowerCase()}
                                            value={value.toLowerCase()}
                                            onClick={() => setActiveTab(value.toLowerCase())}
                                            className={`font-body w-auto text-base font-medium lowercase whitespace-nowrap transition-all duration-300 ${activeTab === value.toLowerCase()
                                                ? "text-gray-800 border-b-[2px] border-logo"
                                                : "text-gray-500 hover:text-gray-900"
                                                }`}
                                        >
                                            {label}
                                        </Tab>
                                    )
                                })}
                            </TabsHeader>
                            <TabsBody
                                animate={{
                                    initial: { y: 250 },
                                    mount: { y: 0 },
                                    unmount: { y: 250 },
                                }}
                                className="overflow-y-auto no-scrollbar mt-20"
                            >
                                {menu?.map(({ value, data }) => (
                                    <TabPanel
                                        className="text-gray-800 font-body"
                                        key={value.toLowerCase()}
                                        value={value.toLowerCase()}
                                    >
                                        {data?.map((item, index) => (
                                            <div key={index} className="py-2">
                                                <h1 className="font-medium lowercase text-base pb-2 text-black">{item?.category}</h1>
                                                {item?.menu_items?.map((menuItem, index) => (
                                                    <MenuCard key={index} item={menuItem} />
                                                ))}
                                            </div>
                                        ))}
                                    </TabPanel>
                                ))}
                            </TabsBody>
                        </Tabs>
                        {cart?.length > 0 && (
                            <div className="flex fixed bottom-[0px] font-body text-white text-lg w-full justify-center z-20 items-center">
                                <button
                                    onClick={() => navigate('/cart')}
                                    className="bg-black flex justify-center lowercase items-center  w-[100%] h-[60px] shadow-lg hover:shadow-xl transition"
                                >
                                    View your order{' '}
                                    <span className="h-[22px] w-[22px] lowercase font-medium bg-white ml-3 text-black rounded-full flex items-center justify-center">
                                        {cart?.length}
                                    </span>
                                </button>
                            </div>
                        )}
                    </>
            }
        </>
    )
}
