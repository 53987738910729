import React from "react";
import Marquee from "react-fast-marquee";
import { image } from "../../assets/image";
import { motion } from "framer-motion";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { Helmet } from "react-helmet";
const WhoWeAre = () => {
    const container = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3,
            },
        },
    };

    const item = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
    };

    return (
        <div>
            <Helmet>
                <title>Who We Are - Saturday</title>
                <meta name="description" content="Discover Saturday's mission to revolutionize restaurant payments. Learn about our journey, culture, and what makes us different." />
                <meta name="keywords" content="Saturday, restaurant payments, QR code payments, fast payment solutions, restaurant technology, restaurant innovation" />
                <link rel="canonical" href="https://www.saturdayapp.in/who-we-are" />
                <meta property="og:title" content="Who We Are - Saturday" />
                <meta property="og:description" content="Discover Saturday's mission to revolutionize restaurant payments. Learn about our journey, culture, and what makes us different." />
                <meta property="og:image" content={image.sample1} />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header />
            {/* First Marquee Section */}
            <div className="h-screen w-full font-body flex flex-col justify-center space-y-10 items-center bg-black">
                <Marquee className="overflow-hidden">
                    <div className="flex justify-start font-heading items-center text-3xl md:text-9xl text-white font-bold">
                        <p>Don't Scroll Down</p>
                        <img
                            src={image.logo}
                            alt="logo"
                            className="h-[40px] mx-4 w-[40px] md:w-[150px] md:h-[150px]"
                        />
                        <p>Don't Scroll Down</p>
                        <img
                            src={image.logo}
                            alt="logo"
                            className="h-[40px] mx-4 w-[40px] md:w-[150px] md:h-[150px]"
                        />
                    </div>
                </Marquee>
                {/* Second Marquee Section */}
                <Marquee direction="right" className="overflow-hidden">
                    <div className="flex justify-start font-heading items-center text-3xl md:text-9xl text-white font-bold">
                        <p>Don't Scroll Down</p>
                        <img
                            src={image.logo}
                            alt="logo"
                            className="h-[40px] mx-4 w-[40px] md:w-[150px] md:h-[150px]"
                        />
                        <p>Don't Scroll Down</p>
                        <img
                            src={image.logo}
                            alt="logo"
                            className="h-[40px] mx-4 w-[40px] md:w-[150px] md:h-[150px]"
                        />
                    </div>
                </Marquee>
            </div>

            {/* Main Section */}
            <div className="h-screen w-full flex flex-col justify-center items-center bg-white px-4 sm:px-8 lg:px-16">
                <div className="h-[500px] w-full sm:w-[80%] md:w-[70%] lg:w-[65%] flex flex-col justify-center items-center">
                    <p className="text-xl sm:text-2xl lg:text-4xl text-center">
                        What if every day felt like Saturday and you had all the time you
                        needed to spend on things that really matter? Reality check – unless
                        you are retired or unemployed, that’s not going to happen. Saturday is
                        changing the way payments are made in restaurants, saving millions
                        of people minutes at the end of their meal, with a tiny 4x4cm QR
                        code. Scan, split, tip, pay in as little as 10 seconds. Whilst we
                        are not making the impossible happen, we are giving people their
                        time back.
                    </p>
                </div>
                <button
                    type="button"
                    className="relative h-12 w-40 sm:h-14 sm:w-48 lg:h-16 lg:w-56 font-medium rounded-full border border-black overflow-hidden group mt-6"
                >
                    <span className="absolute inset-0 bg-black transform scale-x-0 origin-left transition-transform duration-500 group-hover:scale-x-100"></span>
                    <span className="relative z-10 text-black group-hover:text-white transition-colors duration-300">
                        Join Saturday <span className="text-xl">&#8599;</span>
                    </span>
                </button>
            </div>

            {/* Who We Are Section */}
            <div className="h-auto w-full flex flex-col lg:flex-row justify-around items-center bg-white px-4 sm:px-8 lg:px-16 py-10">
                <div className="h-auto lg:h-screen w-full lg:w-[50%] flex flex-col justify-center items-start space-y-6">
                    <h1 className="text-3xl lg:text-4xl font-bold text-center sm:text-2xl">Who We Are</h1>
                    <p className="text-sm lg:text-base text-gray-600 text-center sm:text-sm">
                        Built by restaurateurs for restaurateurs. Co-founded by Akif and
                        Ved, the brains behind Alkesh Chopda, Saturday is on a mission to be on every restaurant
                        table with the world’s fastest payment solution.
                    </p>
                    <div className="relative w-full lg:w-[700px] bg-white border border-gray-200 rounded-xl p-6 space-y-4 overflow-hidden group mt-6">
                        <div className="absolute inset-0 bg-logo transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100 z-0"></div>
                        <h1 className="relative text-2xl lg:text-3xl font-semibold z-10 group-hover:text-white transition-colors duration-300">
                            Pizza to Payments with Akif
                        </h1>
                        <p className="relative text-sm lg:text-lg z-10 group-hover:text-white transition-colors duration-300">
                            Saturday was born out of necessity. It was mid-pandemic, and my restaurant team needed a quick
                            fix to deal with this new way of serving. What I discovered was something far more powerful.
                        </p>
                    </div>

                    <button
                        type="button"
                        className="h-auto w-full lg:w-[700px] text-logo hover:bg-logo hover:text-white rounded-xl border border-gray-300 py-4 px-6 text-lg font-medium transition-colors duration-300 mt-6"
                    >
                        Culture At Saturday With Ved
                    </button>
                </div>

                {/* Image Section */}
                <div className="h-auto lg:h-screen w-full lg:w-[50%] flex justify-center items-center p-5">
                    <img
                        src={image.culture}
                        className="w-full max-w-[500px] h-auto max-h-[700px] rounded-lg shadow-lg object-cover"
                        alt="Culture at Saturday"
                    />
                </div>
            </div>

            {/* What Makes Us Different Section */}
            <div className="h-auto  w-full flex flex-col font-body justify-center items-center space-y-5 py-40">
                <motion.h1
                    className="text-4xl sm:text-6xl font-semibold text-center"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    What Makes Us Different?
                </motion.h1>

                <motion.p
                    className="text-lg sm:text-xl md:text-2xl w-[88%] md:w-[700px] text-center px-4"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                >
                    Time - we never seem to have enough, and we all want more of it. That's where Saturday
                    comes into play, a product built for all types of restaurants and all sorts of consumers.
                </motion.p>

                <motion.div
                    className="flex flex-col justify-start items-center w-[88%] md:w-[700px] space-y-2"
                    variants={container}
                    initial="hidden"
                    animate="visible"
                >
                    {[
                        "QSR To Michelin Star",
                        "Family-Runs to Multi-site Brands",
                        "Take Natives to Your 90-Year-Old Grandma",
                        "Business Lunches to Dinner Parties of 10",
                    ].map((text, index) => (
                        <motion.p
                            key={index}
                            className="text-xl sm:text-2xl font-medium text-center"
                            variants={item}
                        >
                            {text}
                        </motion.p>
                    ))}
                </motion.div>
            </div>


            {/* Final Marquee and Footer */}
            <Marquee className='w-full' direction='right' speed={40}>
                <div className='h-[40px] w-full flex justify-start items-center space-x-2 bg-[#f414e4] text-white text-lg font-light'>
                    <p>Join Saturday<span className='text-xl'> &#8599;</span></p>
                    <p>Join Saturday<span className='text-xl'> &#8599;</span></p>
                    <p>Join Saturday<span className='text-xl'> &#8599;</span></p>
                    <p>Join Saturday<span className='text-xl'> &#8599;</span></p>
                    <p>Join Saturday<span className='text-xl'> &#8599;</span></p>
                    <p>Join Saturday<span className='text-xl'> &#8599;</span></p>
                    <p>Join Saturday<span className='text-xl'> &#8599;</span></p>
                    <p>Join Saturday<span className='text-xl'> &#8599;</span></p>
                    <p>Join Saturday<span className='text-xl'> &#8599;</span></p>
                    <p>Join Saturday<span className='text-xl'> &#8599;</span></p>
                    <p>Join Saturday<span className='text-xl'> &#8599;</span></p>

                    {/* Add more marquee items if necessary */}
                </div>
            </Marquee>
            <Footer screen={true} />
        </div>
    );
};

export default WhoWeAre;
