import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useGoogleOneTapLogin } from 'react-google-one-tap-login'
import { signInWithGooglePopup } from '../../../helper/firebase.utils'
import { image } from '../../../assets/image'
import { IoChevronBack } from 'react-icons/io5'
import { registerAction } from '../../../store/actions/authActions'
import { TailSpin } from 'react-loader-spinner'
export default function CustomerRegsiter() {
    const navigate = useNavigate()
    const logGoogleUser = async () => {
        const response = await signInWithGooglePopup()
        console.log(response)
    }
    const dispatch = useDispatch()
    useGoogleOneTapLogin({
        onError: (error) => console.log(error),
        onSuccess: (response) => console.log(response),
        googleAccountConfigs: {
            client_id:
                '205496834207-48d5vfcmo9cbatc5asjni714bq64h034.apps.googleusercontent.com'
        }
    })
    const [data, setData] = React.useState({
        name: '',
        email: '',
        password: ''
    })
    const [loading, setLoading] = React.useState(false)
    return (
        <AnimatePresence>
            <motion.div
                initial={{ x: 200, opacity: 0.2 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ ease: 'easeInOut', duration: 0.5 }}
                exit={{
                    x: -100,
                    opacity: 0.1,
                    transition: { ease: 'easeInOut', duration: 0.5 }
                }}
                className="  flex flex-col h-[100vh] w-[100vw] font-body"
            >
                <div className="flex justify-between top-0 sticky z-50 left-0 bg-white  px-3 py-3  items-center w-full">
                    <button
                        className="h-[35px] w-[35px] flex justify-center items-center text-logo bg-transparent rounded-full"
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        <IoChevronBack className='text-3xl' />
                    </button>
                    <h1
                        className={`'text-black flex items-center cursor-pointer text-2xl sm:text-3xl md:text-4xl font-body font-light`}
                    >
                        <img src={image.logo} className="h-[40px] pr-3" alt="logo" />
                        Saturday
                    </h1>
                </div>
                <div className="px-5 space-y-5 mt-5 flex flex-col  items-center ">
                    <p className="text-2xl self-center text-black font-semibold font-SUSE">
                        register
                    </p>
                    <div className="w-full space-y-1 font-body">
                        <label htmlFor="name" className="text-sm md:text-base">
                            Name <span className='text-red-500'>*</span>
                        </label>
                        <input
                            type="text"
                            id="name"
                            className="h-12 w-full border-[1.5px] border-gray-200 bg-white px-2 outline-none rounded hover:border-2 hover:border-logo font-body focus:border-logo transition"
                            placeholder="Name*"
                            value={data?.name}
                            onChange={(e) => {
                                setData({ ...data, name: e.target.value })
                            }}
                        />
                    </div>
                    <div className="w-full space-y-1 font-body">
                        <label htmlFor="email" className="text-sm md:text-base">
                            Email address <span className='text-red-500'>*</span>
                        </label>
                        <input
                            type="text"
                            id="email"
                            className="h-12 w-full border-[1.5px] border-gray-200 bg-white px-2 outline-none rounded hover:border-2 hover:border-logo font-body focus:border-logo transition"
                            placeholder="Email address*"
                            value={data?.email}
                            onChange={(e) => {
                                setData({ ...data, email: e.target.value })
                            }}
                        />
                    </div>
                    <div className="w-full space-y-1">
                        <label htmlFor="password" className="text-sm md:text-base">
                            Password <span className='text-red-500'>*</span>
                        </label>
                        <input
                            type="password"
                            id="password"
                            className="h-12 w-full border-[1.5px] border-gray-200 bg-white px-2 outline-none rounded hover:border-2 hover:border-logo font-body focus:border-logo transition"
                            placeholder="Password*"
                            value={data?.password}
                            onChange={(e) => {
                                setData({ ...data, password: e.target.value })
                            }}
                        />

                    </div>
                    <button
                        type="button"
                        disabled={loading}
                        onClick={() => {
                            if (data.email && data.password && data.name) {
                                dispatch(registerAction(data, setLoading))
                            }
                            else {
                                toast.error('please fill all the fields', {
                                    position: 'top-center',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    theme: 'light',
                                    style: {
                                        fontFamily: 'Poppins',
                                    }
                                })
                            }
                        }}
                        className="bg-black h-12 w-full flex justify-center items-center text-white text-sm md:text-base rounded  transition"
                    >
                        {
                            loading ?
                                <TailSpin
                                    visible={true}
                                    height="=20"
                                    width="20"
                                    // radius="9"
                                    color="#fff"
                                    ariaLabel="three-dots-loading"
                                />
                                :
                                "register"
                        }
                    </button>
                    <p className="text-md  text-black font-SUSE">
                        already have an account ?{' '}
                        <button
                            onClick={() => {
                                navigate('/login')
                            }}
                            className="underline text-logo underline-offset-4"
                        >
                            login
                        </button>
                    </p>
                    <div className="flex w-full  justify-evenly items-center">
                        <hr className="w-[30%] h-[1px] bg-black" />
                        <p className="text-md text-black font-SUSE">
                            or login with
                        </p>
                        <hr className="w-[30%] h-[1px] bg-black" />
                    </div>
                    <div className="flex w-full flex-col  justify-between items-center">
                        <button
                            onClick={() => {
                                logGoogleUser()
                            }}
                            className=" border-[1px] w-[100%] rounded bg-white px-2 h-12 border-black flex justify-center items-center space-x-2"
                        >
                            <img
                                alt="google"
                                className="h-[20px] w-[20px]"
                                src={image.google}
                            />
                            <p className="text-sm md:text-base text-black font-medium lowercase">
                                login with Google
                            </p>
                        </button>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    )
}