// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA8Fm8T33OErsuz_wO7u9wFtmRXXY-fkgw",
    authDomain: "saturdayapp-9b755.firebaseapp.com",
    projectId: "saturdayapp-9b755",
    storageBucket: "saturdayapp-9b755.firebasestorage.app",
    messagingSenderId: "205496834207",
    appId: "1:205496834207:web:01697a859f3e452bddeaaa",
    measurementId: "G-HCNLJ8WZGJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

// Function to setup notifications
const setupNotifications = async () => {
    try {
        // Request permission for notifications
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
            console.log('Notification permission granted.');
            const token = await getToken(messaging);
            console.log('FCM Token:', token);
        } else {
            console.log('Notification permission denied.');
        }

        // Handle foreground notifications
        onMessage(messaging, (payload) => {
            console.log('Foreground Message:', payload);
            // Handle the notification or update your UI here
        });
    } catch (error) {
        console.error('Error setting up notifications:', error);
    }
};

// Export setupNotifications function and messaging instance
export { messaging, setupNotifications };

// Google Auth Setup
const provider = new GoogleAuthProvider();

// Force user to select an account if they interact with the provider
provider.setCustomParameters({
    prompt: 'select_account',
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
