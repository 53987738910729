import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import { TypeAnimation } from 'react-type-animation';

export default function ShippingDelivery() {
    return (
        <>
            {/* SEO Optimized Head Tags */}
            <Helmet>
                <title>Shipping and Delivery Policy | Saturday App</title>
                <meta
                    name="description"
                    content="Saturday App allows users to order food through a digital menu by scanning a QR code and paying instantly."
                />
                <meta name="keywords" content="QR Code Food Order, Instant Delivery, Digital Menu, Restaurant Ordering" />
                <meta name="author" content="Saturday App" />
                <meta property="og:title" content="Shipping and Delivery Policy | Saturday App" />
                <meta
                    property="og:description"
                    content="Order food instantly via digital menu by scanning a QR code and enjoy instant delivery at the restaurant."
                />
                <meta property="og:url" content="https://www.saturdayapp.in/shipping-delivery" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header screen={true} />
            {/* Shipping and Delivery Page Content */}
            <main className="flex flex-col justify-start items-center py-10 font-body text-black h-auto w-screen gap-20">
                <section className="text-center pt-10">
                    <TypeAnimation
                        sequence={["Shipping and Delivery Policy", 1000]}
                        speed={50}
                        cursor={false}
                        style={{ fontSize: '50px', fontWeight: 'normal' }}
                        repeat={Infinity}
                    />
                </section>

                <section className="w-[88%] md:w-[50vw] flex flex-col gap-5 text-lg font-normal text-gray-800">
                    <p className="font-semibold">Last Updated: 17 December 2024</p>

                    <p>
                        At <strong>Saturday App</strong>, we make food ordering seamless and instant. Using our platform, customers can access the restaurant's digital menu, order food, and make payments quickly by scanning a QR code.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4">1. QR Code Ordering</h2>
                    <p>
                        Customers can scan the QR code provided at the restaurant to access the digital menu. Once the order is placed, payment can be completed instantly through the app.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4">2. Instant Delivery</h2>
                    <p>
                        Orders are delivered directly to your table at the restaurant as soon as they are prepared. The delivery time is minimal, ensuring a smooth dining experience.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4">3. Payment Confirmation</h2>
                    <p>
                        Once the payment is successfully made through the app, you will receive an instant confirmation via email, SMS, or within the app.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4">4. Customer Support</h2>
                    <p>
                        If you face any issues with your order, delivery, or payment, please contact the restaurant staff or reach out to our support team for quick assistance.
                    </p>
                </section>
            </main>
            <Footer screen={true} />
        </>
    );
}
