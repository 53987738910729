import React from 'react';
import { IoChevronBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

export default function Search() {
    const navigate = useNavigate();
    return (
        <>
            {/* Header */}
            <div className="flex justify-start top-0 sticky z-50 left-0 px-3 py-3 items-center w-full bg-white ">
                <button
                    aria-label="Go back"
                    className="h-[35px] w-[35px] flex justify-center items-center text-logo bg-transparent rounded-full"
                    onClick={() => navigate(-1)}
                >
                    <IoChevronBack className="text-3xl" />
                </button>
                <h1 className="text-black flex ml-3 lowercase items-center text-center cursor-pointer text-2xl sm:text-3xl md:text-4xl font-body font-light">
                    Search
                </h1>
            </div>

            {/* Input Field */}
            <div className="flex justify-center items-start w-full h-[100vh] font-body">
                <div className="w-full flex justify-between px-4">
                    <input
                        type="text"
                        placeholder="Search Food ..."
                        aria-placeholder="Search Food ..."
                        className="h-12 w-full border-[1.5px] border-gray-200 bg-white px-4 outline-none rounded-md hover:border-2 hover:border-logo focus:border-logo transition placeholder-gray-400"
                    />
                </div>
            </div>
        </>
    );
}
