import React from 'react';
import { motion } from 'framer-motion';
import Header from '../../component/Header';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { image } from '../../assets/image';
import Benefits from '../constant/Benifits';
import ContactForm from '../constant/Contact';
import Marquee from 'react-fast-marquee';
import TestimonialsCarousel from '../constant/Testimonial';
import QrDemo from '../constant/QrDemo';
import Footer from '../../component/Footer';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
export default function PayTable() {

    const navigate = useNavigate()
    const images = [image.sample1, image.sample2, image.sample3, image.sample4];

    return (
        <div>
            <Helmet>
                <title>Pay at Table | Quick & Secure Payments for Restaurants</title>
                <meta
                    name="description"
                    content="Transform dining with our Pay at Table feature. Let customers settle bills using integrated QR codes, making payment fast, secure, and hassle-free."
                />
                <meta
                    name="keywords"
                    content="Pay at Table, QR code payment, restaurant payment solutions, fast table payment, secure dining payments"
                />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href="https://www.saturdayapp.in/pay-at-table" />
                <meta property="og:title" content="Pay at Table | Quick & Secure Payments for Restaurants" />
                <meta
                    property="og:description"
                    content="Transform dining with our Pay at Table feature. Let customers settle bills using integrated QR codes, making payment fast, secure, and hassle-free."
                />
                <meta property="og:url" content="https://www.saturdayapp.in/pay-at-table" />
                <meta property="og:image" content={image.sample1} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Pay at Table | Quick & Secure Payments for Restaurants" />
                <meta
                    name="twitter:description"
                    content="Transform dining with our Pay at Table feature. Let customers settle bills using integrated QR codes, making payment fast, secure, and hassle-free."
                />
                <meta name="twitter:image" content={image.sample1} />
            </Helmet>
            <Header screen={true} />
            <div className="min-h-screen w-screen bg-white flex flex-col-reverse font-body lg:flex-row justify-around items-center px-4 md:px-0">
                {/* Text Content */}
                <div className="text-black flex flex-col space-y-8 items-start md:px-10 justify-center w-full lg:w-1/2">
                    <h1 className=' text-5xl text-logo font-bold'>
                        Pay At Table
                    </h1>
                    <motion.h1
                        className="hidden md:flex lg:flex md:text-2xl lg:text-6xl font-semibold text-left lg:text-left font-body text-black mt-8 md:mt-10"
                        initial={{ x: 1000, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 10,
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.5,
                        }}
                    >
                        "Check Please" is a <br /> things of past
                    </motion.h1>
                    <p className='font-body font-normal text-base md:text-xl text-gray-600'>
                        With our integrated QR codes, your customers pay in seconds, right at their table.
                    </p>
                    <button
                        type="button"
                        onClick={() => navigate("/get-started")}
                        className="relative h-10 w-40 sm:h-14 sm:w-48 lg:h-16 lg:w-56 font-medium rounded-full border border-black overflow-hidden group"
                    >
                        {/* Animated Background */}
                        <span className="absolute inset-0 bg-black transform scale-x-0 origin-left transition-transform duration-500 group-hover:scale-x-100"></span>

                        {/* Button Content */}
                        <span className="relative z-10 text-black group-hover:text-white transition-colors duration-300">
                            Get Started
                        </span>
                    </button>
                </div>

                {/* Carousel Section */}
                <div className=" w-full lg:w-[600px] mt-24  lg:mt-0">
                    <Carousel
                        autoPlay
                        infiniteLoop
                        showThumbs={false}
                        showIndicators={false}
                        showArrows={false}
                        interval={5000}
                    >
                        {images.map((item, index) => (
                            <img key={index} className="h-full w-full object-contain" src={item} alt={`carousel-image-${index}`} />
                        ))}
                    </Carousel>
                </div>
            </div>
            <Marquee
                className='w-auto'
                direction='right'
            >
                <img src={image.chai} alt='chai' className='w-[100px] h-[100px] object-contain' />
            </Marquee>
            <TestimonialsCarousel />
            <Benefits />
            <QrDemo />
            <ContactForm />
            <Footer />
        </div>
    )
}
