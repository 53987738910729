import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IoChevronBack } from 'react-icons/io5'
import { getMode, getOrder } from '../../store/actions/sessionAction'
import { useDispatch, useSelector } from 'react-redux'
import BillType from './component/BillType'

export default function Bill() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const session_id = useSelector(state => state.Reducers.session_id)
    const table_id = useSelector(state => state.Reducers.table_id)
    const user_id = parseInt(useSelector(state => state.Reducers.user_id))
    const resdata = useSelector((state) => state.Reducers.restaurant_data)

    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState([])
    const [mode, setMode] = React.useState([])
    const [show, setShow] = React.useState(false)

    React.useEffect(() => {
        dispatch(getOrder(session_id, setLoading, setData))
        dispatch(getMode(setMode, setLoading, session_id, user_id))
    }, [dispatch])

    function getTotal(data) {
        return data.reduce((total, item) => {
            return total + (item.order_total || 0);
        }, 0);
    }

    return (
        <>
            <div className="flex justify-start top-0 sticky z-50 left-0 px-3 py-3 items-center w-full bg-white">
                <button
                    className="h-[35px] w-[35px] flex justify-center items-center text-logo bg-transparent rounded-full"
                    onClick={() => navigate(-1)}
                >
                    <IoChevronBack className="text-3xl" />
                </button>
                <h1 className="text-black flex ml-3 lowercase items-center text-center cursor-pointer text-2xl sm:text-3xl md:text-4xl font-body font-light">
                    pay bill
                </h1>
            </div>
            <BillType setShow={setShow} show={show} mode={mode} order={data} />
            <div
                style={{
                    transition: 'opacity 0.5s ease'
                }}
                className="flex flex-col z-0 bg-white items-center px-5 justify-center w-full py-0 font-body"
            >
                <div
                    style={{
                        backgroundImage: `url(${resdata?.restaurant_images?.SLIDER[2]})`
                    }}
                    className="h-[200px] w-[100vw] bg-cover flex flex-col justify-end relative"
                >
                    <img
                        src={resdata?.image}
                        alt={resdata?.name || 'Restaurant'}
                        className="h-[100px] w-[100px] bg-black object-contain rounded-full -mb-6 mx-auto shadow-md" // Use margin bottom for spacing
                    />
                </div>

                <div className="flex justify-between w-full mt-6 items-start">
                    <div className="space-y-1">
                        <p className=" font-Poppins text-lg font-semibold">
                            Total Bill
                        </p>
                        <p className=" font-Poppins text-xs text-gray-400">
                            Table no. {table_id}
                        </p>
                    </div>
                    <p className=" font-Poppins text-lg font-semibold">
                        {data[0]?.currency} {getTotal(data)}
                    </p>
                </div>
            </div>
            <hr className="w-full"></hr>
            <div
                className="flex flex-col bg-white gap-5 items-start px-5 py-4 pb-10 h-full w-full overflow-y-auto font-body"
            >
                {
                    data?.map((item, index) => (
                        <div key={index} className={`flex flex-row justify-between w-full h-fit text-md`}>
                            <div className="flex flex-row justify-evenly items-center lowercase align-middle space-x-5 max-w-screen">

                                <p className=' font-body text-md'> {item?.name}</p>
                                <p className="bg-black h-5 w-5 flex justify-center items-center rounded-full text-white text-xs">
                                    {item?.quantity}
                                </p>
                            </div>
                            <div>
                                <p> {item?.currency} {item?.order_total}</p>
                            </div>
                        </div >
                    ))
                }
            </div>
            <div className="flex fixed bottom-[0px] font-body text-white text-lg w-full justify-center z-20 items-center">
                <button
                    onClick={() => {
                        setShow(true)
                    }}
                    className="bg-black flex justify-between px-4 lowercase items-center  w-[100%] h-[60px] shadow-lg hover:shadow-xl transition"
                >
                    <p>Total amount : {data[0]?.currency} {getTotal(data)}</p>
                    <p className='px-4 py-1 bg-white text-black'>checkout</p>
                </button>
            </div>

        </>
    )
}
