import axios from 'axios'
import { toast } from 'react-toastify'
import axiosMerchant, { baseURL } from '../../helper/merchant.helper'

export const Init = (setLoading) => {
    return async (dispatch) => {
        try {
            const merchant_access = localStorage.getItem('merchant_access')
            const merchant_data = JSON.parse(localStorage.getItem('merchant_data'))

            dispatch({ type: 'MERCHANT_ACCESS', payload: merchant_access })
            dispatch({
                type: 'MERCHANT_DATA',
                payload: {
                    "name": merchant_data.name,
                    "rest_name": merchant_data.rest_name,
                    "email": merchant_data.email,
                }
            })
        }
        catch {
            dispatch({ type: 'MERCHANT_ACCESS', payload: null })
        }

    }
}

export const loginAction = (data, setLoading, navigate) => {
    setLoading(true)
    return async (dispatch) => {
        await axios
            .post(baseURL + '/login/', data)
            .then((res) => {
                localStorage.setItem('merchant_access', res.data.access)
                localStorage.setItem('merchant_data', JSON.stringify({
                    "name": res.data.name,
                    "rest_name": res.data.restaurant_name,
                    "email": res.data.email,
                }))

                dispatch({ type: 'MERCHANT_ACCESS', payload: res.data.access })
                dispatch({
                    type: 'MERCHANT_DATA',
                    payload: {
                        "name": res.data.name,
                        "rest_name": res.data.restaurant_name,
                        "email": res.data.email,
                    }
                })
                navigate("/merchant/dashboard")
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.response.data.msg)
                toast.error(err.response.data.msg, {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'light',
                    style: {
                        fontFamily: 'Poppins'
                    }
                })
            })
    }
}

export const get_tables_and_menu = (setData, setLoading) => {
    setLoading(true)
    return async (dispatch) => {
        axiosMerchant.get(baseURL + "/get-tables-and-menu-items-count/")
            .then((res) => {
                console.log(res?.data)
                setData(res?.data)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                toast.error(err?.response?.data?.msg, {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'light',
                    style: {
                        fontFamily: 'Poppins'
                    }
                })
            })
    }
}


export const get_todays_orders_and_revenue = (setData, setLoading) => {
    setLoading(true)
    return async (dispatch) => {
        axiosMerchant.get(baseURL + "/get-todays-orders-and-revenue/")
            .then((res) => {
                console.log(res?.data)
                setData(res?.data)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                toast.error(err?.response?.data?.msg, {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'light',
                    style: {
                        fontFamily: 'Poppins'
                    }
                })
            })
    }
}

export const get_orders_and_revenue = (setData, timeframe = 12, setLoading) => {
    setLoading(true)
    return async (dispatch) => {
        axiosMerchant.get(baseURL + `/get-revenue-and-orders-count/?timeframe=${timeframe}`)
            .then((res) => {
                console.log(res?.data)
                setData({
                    "total_orders": res?.data?.total_orders,
                    "total_revenue": res?.data?.total_revenue,
                    "currency": res?.data?.currency,
                    "labelList": Object?.keys(res?.data?.monthly_data),
                    "orderDataList": Object?.values(res?.data?.monthly_data)?.map(item => item?.total_orders),
                    "revenueDataList": Object?.values(res?.data?.monthly_data)?.map(item => item?.total_revenue),
                })
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                toast.error(err?.response?.data?.msg, {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'light',
                    style: {
                        fontFamily: 'Poppins'
                    }
                })
            })
    }
}

export const get_live_orders = (setData, setLoading) => {
    setLoading(true)
    return async (dispatch) => {
        axiosMerchant.get(baseURL + `/get-live-orders/`)
            .then((res) => {
                console.log(res?.data)
                setData(res?.data)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                toast.error(err?.response?.data?.msg, {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'light',
                    style: {
                        fontFamily: 'Poppins'
                    }
                })
            })
    }
}

export const get_kitchen_data = (setData, setLoading) => {
    setLoading(true)
    return async (dispatch) => {
        axiosMerchant.get(baseURL + `/get-kitchen-kot-orders/`)
            .then((res) => {
                console.log("merchant data ==>", res?.data)
                setData(res?.data)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                toast.error(err?.response?.data?.msg, {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'light',
                    style: {
                        fontFamily: 'Poppins'
                    }
                })
            })
    }
}