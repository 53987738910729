import React from 'react'
import Lottie from "lottie-react";
import { animation } from '../../assets/animation';
import { image } from '../../assets/image';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
export default function Success() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(true)
    const rid = useSelector((state) => state.Reducers.restaurant_id)
    const tid = useSelector((state) => state.Reducers.table_id)
    const data = useSelector((state) => state.Reducers.restaurant_data)
    React.useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])
    return (
        <>
            {
                loading ?
                    <div className=' h-screen w-screen flex flex-col font-body justify-center items-center'>
                        <Lottie animationData={animation.order} loop={true} />
                        <p className="text-xs lowercase text-logo font-semibold">
                            sending order to kitchen
                        </p>
                    </div>
                    :
                    <div className=' h-screen w-screen px-5 bg-white flex flex-col font-body justify-center items-center'>
                        <Lottie animationData={animation.done} loop={true} className='h-[200px]' />
                        <h1 className=" font-body text-black font-medium lowercase text-xl text-center">
                            Order Confirmed!
                        </h1>
                        <p className="mt-5 font-bod text-sm lowercase  font-light px-3 text-black text-center">
                            your order have been sent to <span className=' text-logo'>{data?.name}'s kitchen</span>
                        </p>
                        <button
                            type="button"
                            onClick={() => {
                                dispatch({
                                    type: "CLEAR_CART"
                                })
                                navigate(
                                    `/restaurant/?restaurant_id=${rid}&table_id=${tid}`
                                )
                            }}
                            className="bg-black h-10 mt-8 w-full text-white flex justify-center items-center text-sm md:text-base rounded  transition"
                        >
                            back to menu
                        </button>
                        <h1
                            className={`'text-black fixed bottom-0 flex items-center cursor-pointer text-2xl sm:text-3xl md:text-4xl font-body font-light`}
                        >
                            <img src={image.logo} className="h-[40px] pr-3" alt="logo" />
                            Saturday
                        </h1>
                    </div>
            }
        </>

    )
}
