import React from "react";
import Marquee from "react-fast-marquee";
import { image } from "../../assets/image";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

// Reusable Section component
const Section = ({ title, children, className }) => (
  <div className={`h-auto w-full flex flex-col justify-start items-left space-y-5 ${className}`}>
    {title && <h1 className="text-4xl md:text-6xl font-semibold pl-5 md:pl-20">{title}</h1>}
    {children}
  </div>
);

// Reusable Button Component
const Button = ({ label, onClick }) => (
  <button
    type="button"
    className="h-10 md:h-20 w-[150px] md:w-[250px] bg-black text-white text-lg md:text-xl font-semibold hover:scale-105 rounded-full"
    onClick={onClick}
  >
    {label}
  </button>
);

export default function Partners() {
  const navigate = useNavigate();
  return (
    <>
      <Header screen={true} />
      <div className="h-auto w-full flex flex-col justify-start items-center font-body">
        {/* First Section */}
        <div className="min-h-screen w-full bg-white flex pb-5 flex-col-reverse font-body lg:flex-row justify-around items-center px-4 md:px-0">
          {/* Text Content */}
          <div className="text-black flex flex-col space-y-8 items-start md:px-10 justify-center w-full lg:w-1/2">


            <h1 className=' text-5xl text-logo font-bold'>
              Partners
            </h1>
            <motion.h1
              className="hidden md:flex lg:flex md:text-2xl lg:text-6xl font-semibold text-left lg:text-left font-body text-black mt-8 md:mt-10"
              initial={{ x: 1000, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 10,
                duration: 1,
                ease: "easeOut",
                delay: 0.5,
              }}
            >
              We already collaborate <br /> with your day-to-day tools.
            </motion.h1>
            <p className='font-body font-normal text-base md:text-xl text-gray-600'>
              We work with the biggest players in the market to make your experience as worry-free as possible
            </p>
            <button
              type="button"
              onClick={() => {
                navigate("/become-a-partner");
              }}
              className="relative h-10 w-44 sm:h-14 sm:w-48 lg:h-16 lg:w-56 font-medium rounded-full border border-black overflow-hidden group"
            >
              {/* Animated Background */}
              <span className="absolute inset-0 bg-black transform scale-x-0 origin-left transition-transform duration-500 group-hover:scale-x-100"></span>

              {/* Button Content */}
              <span className="relative z-10 text-black group-hover:text-white transition-colors duration-300">
                Become a Partner
              </span>
            </button>
          </div>

          {/* Carousel Section */}
          <div className="w-full lg:w-[600px] mt-10 lg:mt-0">
            <img
              src={image.partner}
              alt="Partners"
              className="h-full w-full object-contain"
            />
          </div>
        </div>

        {/* Horizontal Line */}
        <hr className="w-[92%] h-[2px] bg-gray-400 my-10" />

        {/* Points Of Sale Section */}
        <Section title="Points Of Sale">
          <div className="h-[200px] w-full flex justify-start items-center gap-5 ">
            {/* Add Point of Sale Content */}
          </div>
          <div className="h-[200px] w-full flex justify-start items-center gap-5 ">
            {/* Add Point of Sale Content */}
          </div>
        </Section>

        {/* Horizontal Line */}
        <hr className="w-[92%] h-[2px] bg-gray-400 my-10" />

        {/* Loyalty Section */}
        <Section title="Loyalty">
          <div className="h-[200px] w-full flex justify-start items-center gap-5 ">
            {/* Add Loyalty Content */}
          </div>
        </Section>

        {/* Horizontal Line */}
        <hr className="w-[92%] h-[2px] bg-gray-400 my-10" />

        {/* Aggregators Section */}
        <Section title="Aggregators">
          <div className="h-[200px] w-full flex justify-start items-center gap-5 pl-5 ">
            {/* Add Aggregator Content */}
          </div>
        </Section>

        {/* Marquee Section */}
        <div className="h-auto w-full flex flex-col justify-center items-center bg-black text-white">
          <Marquee className="overflow-hidden">
            <div className="flex justify-start items-center text-4xl md:text-9xl text-white font-bold py-10 md:py-20">
              <p className="px-4">Join The Family</p>
              <img
                src={image.logo}
                alt="Logo"
                className="h-[50px] md:h-[150px] w-[50px] md:w-[150px] "
              />
              <p className="px-4">Join The Family</p>
              <img
                src={image.logo}
                alt="Logo"
                className="h-[50px] md:h-[150px] w-[50px] md:w-[150px] "
              />
              <p className="px-4">Join The Family</p>
              <img
                src={image.logo}
                alt="Logo"
                className="h-[50px] md:h-[150px] w-[50px] md:w-[150px] "
              />
              <p className="px-4">Join The Family</p>
              <img
                src={image.logo}
                alt="Logo"
                className="h-[50px] md:h-[150px] w-[50px] md:w-[150px] "
              />
            </div>
          </Marquee>
        </div>

        {/* Final Background Section */}
        <div
          onClick={() => {
            navigate("/become-a-partner");
          }}
          className="h-[50vh] md:h-screen w-full bg-black flex justify-center items-center bg-[url('https://sundayapp.com/app/uploads/2024/03/Partners-LanguageUS-DeviceDesktop2x.png')] bg-contain bg-center bg-no-repeat hover:scale-105 transition-all duration-700" />
      </div>
      <Footer screen={true} />
    </>
  );
}
