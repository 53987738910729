import React from "react";
import { IoMdStopwatch } from "react-icons/io";
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import Marquee from "react-fast-marquee";
import { image } from "../../assets/image";
import Header from "../../component/Header";
import ContactForm from "./Contact";
import Footer from "../../component/Footer";
import { pdf } from "../../assets/pdf";

// Card Component for Reusability
const Card = ({ card }) => {
  return (
    <div className="relative w-full sm:w-[350px] md:w-[450px] h-[500px] flex flex-col group hover:scale-105 transition-all duration-700">
      {/* Card Image */}
      <img
        src={card.image}
        className="block w-full h-[300px] hover:scale-105 transition-all duration-700"
        alt={card.title}
      />

      {/* Card Info */}
      <div className="flex items-center gap-5 mt-2">
        <div className="bg-[#fff0f5] text-logo font-semibold text-sm px-3 py-1 rounded-lg">
          {card.category}
        </div>
        <h1 className="text-gray-600 text-md">{card.date}</h1>
        <div className="flex items-center gap-2">
          <IoMdStopwatch className="text-sm text-gray-600" />
          <h1 className="text-md text-gray-600">{card.readTime}</h1>
        </div>
      </div>

      {/* Title */}
      <h1 className="text-2xl font-semibold mt-2">{card.title}</h1>

      {/* Overlay Section */}
      <div className="absolute bottom-0 left-0 right-0 bg-white w-full h-0 overflow-hidden transition-all duration-700 ease-in-out group-hover:h-[70%]">
        <div className="flex flex-col items-left space-y-5 justify-center p-5 absolute inset-0">
          <div className="flex items-center gap-5">
            <div className="bg-gray-500 text-white font-semibold text-sm px-3 py-1 rounded-lg">
              {card.category}
            </div>
            <h1 className="text-gray-600 text-md">{card.date}</h1>
            <div className="flex items-center gap-2">
              <IoMdStopwatch className="text-sm text-gray-600" />
              <h1 className="text-md text-gray-600">{card.readTime}</h1>
            </div>
          </div>
          <h1 className="text-2xl text-black font-semibold text-left mt-5">{card.title}</h1>
          <hr className="h-[1px] bg-gray-500 w-full my-5" />
          <p className="text-gray-600 text-left">{card.description}</p>
          <p className="text-3xl font-semibold text-left underline underline-offset-8">Read Article</p>
        </div>
      </div>
    </div>
  );
};

// Blog Page
export default function Blog() {
  // Card data array (Example for testing)
  const cards = [
    // {
    //   id: 1,
    //   image: require("../assets/card1.jpg"),
    //   category: "BLOG",
    //   date: "AUGUST 2024",
    //   readTime: "3 MIN",
    //   title: "Everything You Need To Know About Tip Pooling",
    //   description: "Tip pooling is a way to distribute tips among restaurant employees...",
    // },
    // {
    //   id: 2,
    //   image: require("../assets/card2.png"),
    //   category: "FOOD",
    //   date: "MAY 2024",
    //   readTime: "7 MIN",
    //   title: "Online reputation for restaurants: A Comprehensive Guide to Growing Google Reviews",
    //   description: "Most customers will check out your google reviews before visiting your restaurant...",
    // },
    // Additional cards...
  ];

  return (
    <>
      <Header />

      <div className="font-body overflow-x-hidden">
        {/* Header Section */}
        <div className="bg-black pt-16 pb-4 flex flex-col justify-center items-center space-y-10 px-5">
          <h1 className="text-6xl md:text-[120px] text-white font-semibold text-center w-full max-w-4xl">
            The Restaurant Blog : Explore The World Of Dining With Saturday
          </h1>
          <p className="text-lg sm:text-xl font-semibold text-white">
            Everything You Need To Make Your Life Easier
          </p>
        </div>

        {/* Cards Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 px-5 sm:px-10 py-16">
          {cards.map((card) => (
            <Card key={card.id} card={card} />
          ))}
        </div>

        {/* Load More & Categories */}
        <div className="text-center py-10">
          <button type="button" className="h-16 w-[150px] bg-black text-white font-semibold text-xl hover:scale-105 rounded-full transition-all duration-500">
            Load More
          </button>
          <h1 className="text-2xl text-gray-500 mt-10">EXPLORE CATEGORIES</h1>
          <div className="flex justify-center w-full md:w-auto items-center gap-5 mt-5">
            <button type="button" className="h-16 w-[300px] hover:bg-[#fff0f5] hover:text-logo bg-black text-white -rotate-6 font-medium  text-base md:text-3xl hover:scale-105 rounded-full transition-all duration-500">
              Restaurant Toolkit
            </button>
            <button type="button" className="h-16 w-[300px] hover:bg-[#fff0f5] hover:text-logo bg-black text-white rotate-6 font-medium text-base md:text-3xl hover:scale-105 rounded-full transition-all duration-500">
              Saturday News
            </button>
          </div>
          <button type="button" className="h-16 w-[300px] hover:bg-[#fff0f5] hover:text-logo bg-black text-white rotate-6 font-medium text-base md:text-3xl hover:scale-105 rounded-full mt-5 transition-all duration-500">
            Testimonials
          </button>
        </div>

        {/* Case Studies Marquee */}
        <div className="bg-black py-20">
          <Marquee direction="right" className="overflow-hidden">
            <div className="flex justify-start items-center text-[40px] md:text-[200px] text-white font-semibold">
              <p className="px-2">Case Studies</p>
              <img src={image.logo} alt="logo" className="h-[40px] w-[40px] md:h-[150px] md:w-[150px]" />
              <p className="px-2">Case Studies</p>
              <img src={image.logo} alt="logo" className="h-[40px] w-[40px] md:h-[150px] md:w-[150px]" />
            </div>
          </Marquee>
        </div>

        {/* Footer Information */}
        <div className="bg-gray-100 py-16">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-10 px-5">
            <div className="text-center">
              <h1 className="text-3xl font-medium">Doing Things Differently</h1>
              <p className="text-xl text-gray-600 mt-5">Our Company manifesto highlights everything we believe in...</p>
              <button
                onClick={() => {
                  window.open(pdf.manifesto);
                }}
                className="w-[300px] h-16 rounded-full border-[1.5px] border-gray-300 bg-white text-base font-medium mt-5">Download Saturday's Manifesto &#8595;</button>
            </div>
            <div className="text-center">
              <h1 className="text-3xl font-medium">The Fastest Way to Pay</h1>
              <p className="text-xl text-gray-600 mt-5">Meet Saturday, the easiest and quickest way for customers to pay...</p>
              <button className="w-[300px] h-16 rounded-full border-[1.5px] border-gray-300 bg-white text-base font-medium mt-5">Find Out More &#8595;</button>
            </div>
          </div>
        </div>

        {/* Social Media Marquee */}
        <div className="py-20">
          <Marquee direction="right" className="overflow-hidden">
            <div className="flex justify-start items-center text-[40px] sm:text-[120px] font-heading font-semibold">
              <p onClick={() => window.open("https://www.facebook.com/saturdayapp.in/")} className="px-2">Facebook</p>
              <FaFacebookSquare onClick={() => window.open("https://www.facebook.com/saturdayapp.in/")} className="text-logo font-bold text-[40px] sm:text-[120px]" />
              <p onClick={() => window.open("https://www.instagram.com/saturdayapp.in/")} className="px-2">Instagram</p>
              <FaInstagramSquare onClick={() => window.open("https://www.instagram.com/saturdayapp.in/")} className="text-logo font-bold text-[40px] sm:text-[120px]" />
              <p onClick={() => window.open("https://www.linkedin.com/company/saturdayapp/")} className="px-2">LinkedIn</p>
              <FaLinkedin onClick={() => window.open("https://www.linkedin.com/company/saturdayapp/")} className="text-logo font-bold text-[40px] sm:text-[120px]" />
            </div>
          </Marquee>
        </div>

        {/* Contact Form */}
        <div className="h-auto w-screen flex justify-center items-center gap-7">
          <ContactForm />
        </div>
      </div>

      <Footer />
    </>
  );
}
