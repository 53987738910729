import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import { TypeAnimation } from 'react-type-animation';

export default function CancellationRefund() {
    return (
        <>
            {/* SEO Optimized Head Tags */}
            <Helmet>
                <title>Cancellation and Refund Policy | Saturday App</title>
                <meta
                    name="description"
                    content="Saturday App allows users to order food through a digital menu by scanning a QR code and paying instantly. Learn more about our cancellation and refund policy."
                />
                <meta name="keywords" content="Cancellation Policy, Refund Policy, QR Code Orders, Digital Menu" />
                <meta name="author" content="Saturday App" />
                <meta property="og:title" content="Cancellation and Refund Policy | Saturday App" />
                <meta
                    property="og:description"
                    content="Learn about our cancellation and refund process for QR code-based food orders placed through Saturday App."
                />
                <meta property="og:url" content="https://www.saturdayapp.in/cancellation-refund" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header screen={true} />
            {/* Cancellation and Refund Page Content */}
            <main className="flex flex-col justify-start items-center py-10 font-body text-black h-auto w-screen gap-20">
                <section className="text-center pt-10">
                    <TypeAnimation
                        sequence={["Cancellation and Refund Policy", 1000]}
                        speed={50}
                        cursor={false}
                        style={{ fontSize: '50px', fontWeight: 'normal' }}
                        repeat={Infinity}
                    />
                </section>

                <section className="w-[88%] md:w-[50vw] flex flex-col gap-5 text-lg font-normal text-gray-800">
                    <p className="font-semibold">Last Updated: 17 December 2024</p>

                    <p>
                        At <strong>Saturday App</strong>, we aim to offer a seamless and efficient food ordering experience. Since the ordering process involves instant confirmation and preparation, cancellations and refunds are limited.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4">1. Order Cancellation</h2>
                    <p>
                        Once an order is placed and payment is completed through the QR code, the restaurant immediately begins preparation. As a result, cancellations are not allowed after confirmation.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4">2. Refund Policy</h2>
                    <p>
                        Refunds are only applicable under the following conditions:
                    </p>
                    <ul className="list-disc pl-6">
                        <li>Incorrect items delivered that differ from the order placed.</li>
                        <li>Order not delivered due to technical or operational issues at the restaurant.</li>
                    </ul>
                    <p>
                        If any of the above situations occur, please contact the restaurant staff immediately or reach out to our support team. Refunds, if approved, will be processed to the original payment method within 5-7 business days.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4">3. Contact Support</h2>
                    <p>
                        For any issues or clarifications regarding cancellations and refunds, please contact our support team or the restaurant staff directly. We are here to assist you.
                    </p>
                </section>
            </main>
            <Footer screen={true} />
        </>
    );
}
