import React from 'react'
import SideBar from '../component/SideBar'
import { image } from '../../../assets/image'
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from '@material-tailwind/react';
import TopLoadingBar from 'react-top-loading-bar'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMenu, getRestaurant } from '../../../store/actions/homeActions';
export default function MenuManagment() {
    const [loading, setLoading] = React.useState(true);
    const [progress, setProgress] = React.useState(0)
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    React.useEffect(() => {
        // const params = new URLSearchParams(location?.search);
        // dispatch(
        //     storeQRData(
        //         params.get('restaurant_id'),
        //         params.get('table_id')
        //     )
        // )
        dispatch(getRestaurant(2, setLoading, setProgress));
        dispatch(getMenu(2));
        setTimeout(() => {
            setLoading(false);
            setActiveTab(menu[0]?.value?.toLowerCase())
        }, 5000);
    }, [dispatch]);

    const cart = useSelector((state) => state.Reducers.cart);
    const menu = useSelector((state) => state.Reducers.menu);
    const [activeTab, setActiveTab] = React.useState(menu[0]?.value?.toLowerCase());
    return (
        <div className="bg-background min-h-screen flex font-body">
            <TopLoadingBar
                color="#f414e4"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <SideBar />
            <main className="flex-1 h-screen overflow-y-scroll flex flex-col p-4 space-y-6">
                <div className=" w-full  flex justify-end items-center">
                    <h1
                        className={`flex items-center self-start  cursor-pointer text-xl sm:text-xl md:text-xl font-body font-medium`}
                    >
                        <img src={image.logo} className="h-[30px] pr-3" alt="logo" />
                        admin
                    </h1>
                </div>
                <div className=" w-full flex justify-between items-center  self-center">
                    <div className="">
                        <h1 className=' font-body text-3xl font-medium'>
                            Menu Management
                        </h1>
                        <p className=' font-body font-normal text-sm text-gray-500'>Easily update and manage your restaurant's menu items.</p>
                    </div>
                    <button
                        className="w-auto bg-logo border py-2 px-4 text-center flex justify-between items-center text-white hover:bg-gray-100"
                    >
                        Add Menu Item
                    </button>
                </div>
                <Tabs value={menu[0]?.value?.toLowerCase()}>
                    <TabsHeader
                        className="rounded-none fixed w-screen  border-b mt-4 z-20 border-blue-gray-50 bg-background  p-0 overflow-x-scroll scrollbar-none scrollbar-thumb-transparent no-scrollbar"
                        indicatorProps={{
                            className: "bg-white border-gray-900 rounded-none",
                        }}
                    >
                        {menu?.map(({ label, value }) => {
                            return (
                                <Tab
                                    key={value.toLowerCase()}
                                    value={value.toLowerCase()}
                                    onClick={() => setActiveTab(value.toLowerCase())}
                                    className={`font-body w-auto text-base font-medium lowercase whitespace-nowrap transition-all duration-300 ${activeTab === value.toLowerCase()
                                        ? "text-gray-800 border-b-[2px] border-logo"
                                        : "text-gray-500 hover:text-gray-900"
                                        }`}
                                >
                                    {label}
                                </Tab>
                            )
                        })}
                        <button className=' text-logo px-3 font-semibold'>
                            + Add
                        </button>
                    </TabsHeader>
                    <TabsBody
                        animate={{
                            initial: { y: 250 },
                            mount: { y: 0 },
                            unmount: { y: 250 },
                        }}
                        className="overflow-y-auto no-scrollbar mt-10"
                    >
                        {menu?.map(({ value, data }) => (
                            <TabPanel
                                className="text-gray-800 font-body"
                                key={value.toLowerCase()}
                                value={value.toLowerCase()}
                            >
                                {data?.map((item, index) => (
                                    <div key={index} className="py-2">
                                        <h1 className="font-medium lowercase text-base pb-2 text-black">{item?.category}</h1>
                                        <div className="overflow-auto">
                                            <table className="w-full text-sm text-left text-gray-500 font-body mt-5">
                                                <thead className="text-sm text-gray-700 capitalize font-normal bg-gray-100">
                                                    <tr className="bg-gray-100 py-3">
                                                        <th scope="col" className=" capitalize py-2  text-gray-800 font-medium text-sm">Index</th>
                                                        <th scope="col" className=" capitalize py-2  text-gray-800 font-medium text-sm">Icon</th>
                                                        <th scope="col" className="capitalize py-2 text-gray-800 font-medium text-sm">Name</th>
                                                        <th scope="col" className="capitalize py-2 text-gray-800 font-medium text-sm">Description</th>
                                                        <th scope="col" className="capitalize py-2 text-gray-800 font-medium text-sm">Price</th>
                                                        <th scope="col" className="capitalize py-2 text-gray-800 font-medium text-sm">Actions</th>

                                                    </tr>
                                                </thead>
                                                <tbody className='text-left'>
                                                    {item?.menu_items?.map((menuItem, index) => (
                                                        <tr key={menuItem.id || index} className="even:bg-gray-50 ">
                                                            <td className="text-gray-800 font-body font-medium">{index + 1}</td>
                                                            <img
                                                                alt="alt"
                                                                src={menuItem?.image}
                                                                className="w-[50px] h-[50px] object-cover rounded-full my-2"
                                                            />
                                                            <td className="text-gray-800 lowercase font-body font-semibold">{menuItem?.name}</td>
                                                            <td className="text-gray-800 lowercase font-body">{menuItem?.description?.length > 60 ? `${menuItem?.description.slice(0, 80)}...` : menuItem?.description}
                                                            </td>
                                                            <td className="text-gray-800 lowercase font-body">{menuItem?.price}</td>
                                                            <td className=" space-x-2">
                                                                <button className=" text-white font-medium ">
                                                                    <img src={image.edit} className='h-6 w-6' />
                                                                </button>
                                                                <button className=" text-white font-medium ">
                                                                    <img src={image.trash} className='h-6 w-6' />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ))}

                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </main>
        </div>
    )
}
