import React from 'react'
import { useLocation } from 'react-router-dom'
import { IoChevronBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { updateQty } from '../../store/actions/cartActions';
import { toast } from 'react-toastify';
import { getCustum } from '../../store/actions/sessionAction';
export default function ItemInfo() {
    const { state } = useLocation()
    const [qty, setQty] = React.useState(1);
    const [show, setShow] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState([])
    const [notes, setNotes] = React.useState("")


    const dispatch = useDispatch()
    const navigate = useNavigate()

    React.useEffect(() => {
        dispatch(getCustum(state?.id, setLoading, setData))
    }, [dispatch, state?.id])
    return (
        <div className="bg-white w-full h-[100vh] font-body">
            <div className="flex justify-start top-0 sticky z-50 left-0 bg-white  px-3 py-3  items-center w-full">
                <button
                    className="h-[35px] w-[35px] flex justify-center items-center text-logo bg-transparent rounded-full"
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    <IoChevronBack className='text-3xl' />
                </button>
                <h1
                    className={`text-black lowercase flex ml-3 items-center text-center cursor-pointer text-2xl sm:text-3xl md:text-4xl font-body font-light`}
                >
                    Product Info
                </h1>
            </div>
            <div className=" mx-auto pb-6">
                {/* Product Image */}
                <div className="relative w-full h-72 md:h-96 overflow-hidden shadow-lg mb-6">
                    <img
                        src={state.image}
                        alt={state.name}
                        className="object-cover w-screen h-full"
                    />
                </div>

                {/* Product Details */}
                <div className="space-y-4 px-2">
                    {/* Product Name */}
                    <div className="flex items-center justify-between ">
                        <h2 className="text-xl lowercase font-semibold text-black">{state.name}</h2>
                        <div className="flex items-center justify-start ">
                            <span className="text-xl font-normal text-logo">{state.currency}</span>
                            <span className="text-xl font-normal text-logo mr-4">{state.price}</span>
                        </div>

                    </div>

                    {
                        state?.is_customizable ?
                            <div className="flex justify-between items-center">
                                <button className='bg-black px-4 lowercase py-1 text-white'>
                                    Customize
                                </button>
                                <div className="px-2 flex justify-end items-center space-x-3">
                                    <button
                                        onClick={() => {
                                            if (qty > 1) {
                                                setQty(qty - 1);
                                                dispatch(updateQty(state?.id, qty - 1));
                                            } else {
                                                dispatch({ type: 'DELETE_ITEM', payload: state?.id });
                                            }
                                        }}
                                        className="h-[25px] w-[25px] bg-gray-300 font-bold rounded-full flex justify-center items-center"
                                    >
                                        -
                                    </button>
                                    <p className="text-md font-bold text-gray-800">{qty}</p>
                                    <button
                                        onClick={() => {
                                            setQty(qty + 1);
                                            dispatch(updateQty(state?.id, qty + 1));
                                        }}
                                        className="h-[25px] w-[25px] bg-black font-bold text-white rounded-full flex justify-center items-center"
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            :
                            <div className="mt-5 px-2 flex justify-end items-center space-x-3">
                                <button
                                    onClick={() => {
                                        if (qty > 1) {
                                            setQty(qty - 1);
                                            dispatch(updateQty(state?.id, qty - 1));
                                        } else {
                                            dispatch({ type: 'DELETE_ITEM', payload: state?.id });
                                        }
                                    }}
                                    className="h-[25px] w-[25px] bg-gray-300 font-bold rounded-full flex justify-center items-center"
                                >
                                    -
                                </button>
                                <p className="text-md font-bold text-gray-800">{qty}</p>
                                <button
                                    onClick={() => {
                                        setQty(qty + 1);
                                        dispatch(updateQty(state?.id, qty + 1));
                                    }}
                                    className="h-[25px] w-[25px] bg-black font-bold text-white rounded-full flex justify-center items-center"
                                >
                                    +
                                </button>
                            </div>
                    }
                    {/* Product Description */}
                    <p className="text-xs lowercase text-gray-700 text-justify">{state.description}</p>
                </div>
            </div>
            <button
                onClick={() => {
                    dispatch({
                        type: 'ADD_TO_CART',
                        payload: {
                            ...state,
                            qty: qty,
                        },
                    })
                    toast.success('Product Added to cart.', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: 'light'
                    })
                }}
                className="bg-black flex justify-center fixed bottom-0 text-white lowercase items-center  w-[100%] h-[60px] shadow-lg hover:shadow-xl transition"
            >
                add to cart
            </button>
        </div>
    )
}
