import React, { useState, useEffect } from 'react';
import SideBar from '../component/SideBar';
import { image } from '../../../assets/image';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRestaurant } from '../../../store/actions/homeActions';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {
    AiFillFacebook,
    AiFillInstagram,
    AiFillTwitterCircle
} from 'react-icons/ai';
import { TbWorldWww } from "react-icons/tb";
import { HiOutlineX } from 'react-icons/hi';

export default function Restaurant() {
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        tagline: "",
        restaurantImage: "",
        heroImage: "",
        sliderImages: [],
        socials: {
            facebook: "",
            instagram: "",
            twitter: "",
            website: ""
        },
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e, type) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (type === 'heroImage') {
                    setFormData({ ...formData, heroImage: reader.result });
                } else if (type === 'restaurantImage') {
                    setFormData({ ...formData, restaurantImage: reader.result });
                } else if (type === 'sliderImage') {
                    const updatedSliderImages = [...formData.sliderImages, reader.result];
                    setFormData({ ...formData, sliderImages: updatedSliderImages });
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveSliderImage = (index) => {
        const updatedSliderImages = formData.sliderImages.filter((_, i) => i !== index);
        setFormData({ ...formData, sliderImages: updatedSliderImages });
    };

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const rid = searchParams.get('restaurant_id');
    const data = useSelector((state) => state.Reducers.restaurant_data);
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        dispatch(getRestaurant(rid, setLoading, setProgress));
    }, [dispatch, location, rid]);

    const handleSaveChanges = () => {
        alert('Restaurant data updated!');
    };
    const customArrowRight = (onClickHandler, hasNext) => (
        hasNext && (
            <button
                onClick={onClickHandler}
                className="absolute bottom-1 right-4 transform -translate-y-1/2 bg-logo text-white h-[40px] w-[40px] rounded-full shadow-md hover:bg-opacity-80"
                style={{ zIndex: 10 }}
            >
                &#8594; {/* You can replace this with an icon */}
            </button>
        )
    );
    // Check if data is loaded and valid
    const restaurantSocials = data?.restaurant_socials || {};

    return (
        <div className="bg-background flex font-body">
            <SideBar />
            <main className="flex-1 h-screen overflow-y-scroll flex flex-col p-4 space-y-6">
                <div className="w-full flex justify-end items-center">
                    <h1 className={`flex items-center self-start cursor-pointer text-xl sm:text-xl md:text-xl font-body font-medium`}>
                        <img src={image.logo} className="h-[30px] pr-3" alt="logo" />
                        admin
                    </h1>
                </div>
                <div className="w-full self-center">
                    <div>
                        <h1 className='font-body text-3xl font-medium'>
                            Restaurant Details
                        </h1>
                        <p className='font-body font-normal text-sm text-gray-500'>
                            Streamline and oversee all aspects of your restaurant landing page with ease.
                        </p>
                    </div>
                </div>
                <div className="w-full p-4 flex justify-between items-start h-[85%]">
                    <div className="w-[68%] overflow-y-scroll no-scrollbar h-full">
                        {/* Editable Restaurant Details */}
                        <div className="mb-4">
                            <label className="block text-lg font-medium">Restaurant Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full p-2 outline-none border border-gray-300 "
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-lg font-medium">Description</label>
                            <textarea
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                className="w-full p-2 border outline-none border-gray-300 "
                                rows="4"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-lg font-medium">Tagline</label>
                            <input
                                type="text"
                                name="tagline"
                                value={formData.tagline}
                                onChange={handleChange}
                                className="w-full p-2 border outline-none border-gray-300 "
                            />
                        </div>

                        {/* Hero Image Upload */}
                        <div className="mb-4">
                            <label className="block text-lg font-medium">Hero Image</label>
                            <div className="flex w-full space-x-2 h-auto">
                                <input
                                    type="file"
                                    onChange={(e) => handleImageChange(e, 'heroImage')}
                                    className="p-2 border outline-none border-gray-300 "
                                />
                            </div>
                        </div>

                        {/* Restaurant Image Upload */}
                        <div className="mb-4">
                            <label className="block text-lg font-medium">Restaurant Icon</label>
                            <div className="flex w-full space-x-2 h-auto">
                                <input
                                    type="file"
                                    onChange={(e) => handleImageChange(e, 'restaurantImage')}
                                    className="p-2 border outline-none border-gray-300 "
                                />
                            </div>
                        </div>

                        {/* Slider Images Upload */}
                        <div className="mb-4">
                            <label className="block text-lg font-medium">Slider Images</label>
                            <input
                                type="file"
                                onChange={(e) => handleImageChange(e, 'sliderImage')}
                                className="w-full p-2 border outline-none border-gray-300 "
                            />
                            {formData.sliderImages.length > 0 && (
                                <div className="mt-2 grid grid-cols-3 gap-2">
                                    {formData.sliderImages.map((icon, index) => (
                                        <div key={index} className="relative">
                                            <img src={icon} alt={`Slider ${index + 1}`} className="w-full h-[150px] object-cover" />
                                            <button
                                                className="absolute top-1 right-1 bg-red-500 flex justify-center items-center text-white rounded-full h-[20px] w-[20px]"
                                                onClick={() => handleRemoveSliderImage(index)}
                                            >
                                                <HiOutlineX />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="mb-4 space-y-2">
                            <label className="block text-lg font-medium">Social</label>
                            <input
                                type="text"
                                name="facebook"  // Use "facebook" here
                                value={formData.socials.facebook}
                                placeholder="Facebook URL"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        socials: {
                                            ...formData.socials,
                                            facebook: e.target.value  // Update facebook URL directly
                                        }
                                    })
                                }
                                className="w-full p-2 border border-gray-300 outline-none"
                            />
                            <input
                                type="text"
                                name="instagram"  // Use "instagram" here
                                value={formData.socials.instagram}
                                placeholder="Instagram URL"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        socials: {
                                            ...formData.socials,
                                            instagram: e.target.value  // Update instagram URL directly
                                        }
                                    })
                                }
                                className="w-full p-2 border border-gray-300 outline-none"
                            />
                            <input
                                type="text"
                                name="twitter"  // Use "twitter" here
                                value={formData.socials.twitter}
                                placeholder="Twitter URL"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        socials: {
                                            ...formData.socials,
                                            twitter: e.target.value  // Update twitter URL directly
                                        }
                                    })
                                }
                                className="w-full p-2 border border-gray-300 outline-none"
                            />
                            <input
                                type="text"
                                name="website"  // Use "website" here
                                value={formData.socials.website}
                                placeholder="Website URL"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        socials: {
                                            ...formData.socials,
                                            website: e.target.value  // Update website URL directly
                                        }
                                    })
                                }
                                className="w-full p-2 border border-gray-300 outline-none"
                            />
                        </div>


                        {/* Save Button */}
                        <div className="mt-6">
                            <button
                                onClick={handleSaveChanges}
                                className="w-64 bg-blue-500 text-white py-2 "
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>

                    {/* Section for Display */}
                    <div className="w-[30%] h-full border overflow-y-scroll no-scrollbar">
                        {/* Hero Image Display */}
                        <div
                            style={{
                                backgroundImage: `url(${formData.heroImage})`,
                                backgroundSize: 'cover'
                            }}
                            role="banner"
                            aria-label={`Hero section for ${formData.name || 'Restaurant'}`}
                            className="w-full h-[585px] relative"
                        >
                            <div className="relative h-full w-full flex flex-col justify-center items-center">
                                <img
                                    alt="Restaurant Icon"
                                    src={formData.restaurantImage}
                                    className="h-[50px] w-[50px] rounded-full object-contain"
                                />
                                <h1 className="font-heading capitalize w-[88%] text-white font-semibold text-4xl text-center">
                                    {formData.tagline}
                                </h1>
                            </div>
                        </div>

                        {/* View Menu Button */}
                        <div className="w-full h-auto object-contain py-3 flex flex-col justify-center items-center">
                            <h1 className="font-body text-black font-bold text-3xl text-center">
                                {formData.name}
                            </h1>
                            <p className="mt-5 font-body font-light capitalize px-3 text-black text-justify">
                                {formData.description}
                            </p>
                            <button
                                onClick={() => {
                                    // navigate(`/menu/?restaurant_id=${rid}&restaurant_name=${data?.name}&table_id=${table_id}`)
                                }}
                                className="bg-logo outline-none mt-2 self-center font-medium h-[40px] w-[116px] rounded-lg text-base font-body text-white"
                            >
                                View Menu
                            </button>
                        </div>

                        {/* Slider Section */}
                        <div className="w-full object-contain py-5 flex flex-col justify-center items-center">
                            <h1 className="font-body capitalize text-lg pb-5 font-bold text-black text-start w-full px-3">
                                Restaurant Images
                            </h1>
                            <Carousel
                                autoPlay
                                infiniteLoop
                                showThumbs={false}
                                showIndicators={true}
                                showArrows={true}
                                interval={5000}
                                renderArrowNext={(onClickHandler, hasNext) =>
                                    customArrowRight(onClickHandler, hasNext)
                                }
                            >
                                {formData.sliderImages.map((image, index) => (
                                    <img
                                        key={index}
                                        alt={`Slider ${index + 1}`}
                                        src={image}
                                        className="w-full h-[300px] object-cover"
                                    />
                                ))}
                            </Carousel>
                        </div>

                        {/* Social Media Links */}
                        <div className="w-full object-contain flex flex-col justify-center items-center">
                            <h1 className="font-body capitalize text-lg pb-2 font-bold text-black text-start w-full px-3">
                                Restaurant Socials
                            </h1>
                            <div className="flex space-x-3 pb-3">
                                <AiFillFacebook
                                    size={35}
                                    className="text-black"
                                    onClick={() => {
                                        window.open(restaurantSocials.FACEBOOK, '_blank')
                                    }}
                                />
                                <AiFillInstagram
                                    size={35}
                                    className="text-black"
                                    onClick={() => {
                                        window.open(restaurantSocials.INSTAGRAM, '_blank')
                                    }}
                                />
                                <AiFillTwitterCircle
                                    size={35}
                                    className="text-black"
                                    onClick={() => {
                                        window.open(restaurantSocials.TWITTER, '_blank')
                                    }}
                                />
                                <TbWorldWww
                                    size={35}
                                    className="text-black"
                                    onClick={() => {
                                        window.open(restaurantSocials.WEBSITE, '_blank')
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
