import React from 'react';
import { useDispatch } from 'react-redux';
import { addnotes, updateQty } from '../../../store/actions/cartActions';
import { Sheet } from 'react-modal-sheet';
import { getCustum } from '../../../store/actions/sessionAction';
import CustomCard from './CustomCard';
import { HiOutlineX } from 'react-icons/hi';

export default function CartCard({ item }) {
    const [count, setCount] = React.useState(item?.qty);
    const [show, setShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [notes, setNotes] = React.useState("");
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getCustum(item?.id, setLoading, setData));
    }, [dispatch, item?.id]);

    // Calculate total customizations price
    const calculateTotalCustomizationsPrice = () => {
        return data.reduce((total, custom) => {
            const qty = item.customizations[custom.id] || 0;
            return total + custom.price * qty;
        }, 0);
    };

    const totalCustomizationsPrice = calculateTotalCustomizationsPrice();
    const totalPrice = item.price * count + totalCustomizationsPrice;

    return (
        <>
            <Sheet
                isOpen={show}
                onClose={() => setShow(false)}
                snapPoints={[0.7, 0.35, 0]}
                initialSnap={0}
            >
                <Sheet.Container>
                    <Sheet.Header>
                        <div className="p-4 border-b border-gray-300 font-body flex justify-between items-center">
                            <h3 className="text-lg font-medium text-gray-800">Customize {item?.name}</h3>
                            <button
                                onClick={() => setShow(false)}
                            >
                                <HiOutlineX className=' text-2xl text-gray-500' />
                            </button>
                        </div>
                    </Sheet.Header>
                    <Sheet.Content>
                        <div className="p-4 font-body">
                            <p className="text-sm font-semibold text-gray-600">Customize Item</p>
                            <div className="flex flex-wrap justify-between items-center mt-4">
                                {data.map((custom, index) => (
                                    <CustomCard
                                        key={index}
                                        state={item}
                                        custom={custom}
                                        existingQty={item.customizations[custom.id] || 0} // Pass existing quantity
                                    />
                                ))}
                            </div>
                            <p className="text-sm font-semibold text-gray-600 mt-6">Notes</p>
                            <textarea
                                type="text"
                                onChange={(e) => setNotes(e.target.value)}
                                className="h-[80px] border-[1px] text-start p-2 w-full outline-none rounded-md border-logo mt-2"
                            />
                            <div className="flex justify-end gap-4 mt-6 font-body">
                                <button
                                    onClick={() => setShow(false)}
                                    type="button"
                                    className="px-4 py-2 rounded-lg lowercase  text-gray-800 text-md bg-gray-200 hover:bg-gray-300"
                                >
                                    Close
                                </button>
                                <button
                                    onClick={() => {
                                        dispatch(addnotes(item?.id, notes));
                                        setShow(false);
                                    }}
                                    type="button"
                                    className="px-4 py-2 rounded-lg lowercase text-white text-md bg-black"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet>

            <div className="h-[65px] w-[92%]  flex self-center justify-between font-body">
                <div className="w-[60%] flex space-x-3">
                    <img
                        alt="alt"
                        src={item?.image}
                        className="h-[60px] object-cover rounded-full w-[60px]"
                    />
                    <div className="w-[60%]">
                        <h2 className=" font-medium text-sm lowercase flex items-center text-black">
                            {item?.name}
                        </h2>
                        <h2 className=" font-medium text-base text-logo">
                            {item?.currency} {item?.price}
                        </h2>
                        {item?.is_customizable && (
                            <button
                                onClick={() => {
                                    setShow(true)
                                }}
                                className="bg-black py-1 font-bold text-xs rounded-full text-white px-3"
                            >
                                Customize
                            </button>
                        )}
                    </div>
                </div>
                <div className="w-[25%] flex justify-center items-start space-x-3">
                    <button
                        onClick={() => {
                            if (count > 1) {
                                setCount(count - 1);
                                dispatch(updateQty(item?.id, count - 1));
                            } else {
                                dispatch({ type: 'DELETE_ITEM', payload: item?.id });
                            }
                        }}
                        className="h-[25px] w-[25px] bg-gray-300 font-bold rounded-full flex justify-center items-center"
                    >
                        -
                    </button>
                    <p className="text-md font-bold text-gray-800">{count}</p>
                    <button
                        onClick={() => {
                            setCount(count + 1);
                            dispatch(updateQty(item?.id, count + 1));
                        }}
                        className="h-[25px] w-[25px] bg-black font-bold text-white rounded-full flex justify-center items-center"
                    >
                        +
                    </button>
                </div>
            </div>
        </>
    );
}
