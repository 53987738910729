import React from 'react'
import {
    AiFillFacebook,
    AiFillInstagram,
    AiFillLinkedin,
    AiFillTwitterCircle
} from 'react-icons/ai'
import TopLoadingBar from 'react-top-loading-bar'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { storeQRData, getRestaurant } from '../../store/actions/homeActions'
import { Init, startSession } from '../../store/actions/sessionAction'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { image } from '../../assets/image'
export default function CustomerHome() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const rid = searchParams.get('restaurant_id');
    const tid = searchParams.get('table_id');
    const data = useSelector((state) => state.Reducers.restaurant_data)
    const session_id = useSelector((state) => state.Reducers.session_id)
    const user_id = useSelector((state) => state.Reducers.user_id)
    const table_id = useSelector((state) => state.Reducers.table_id)
    const [loading, setLoading] = React.useState(true)
    const [progress, setProgress] = React.useState(0)
    const [connected, setConnected] = React.useState(false)

    // const cart = useSelector((state) => state.Reducers.cart)
    const customArrowRight = (onClickHandler, hasNext) => (
        hasNext && (
            <button
                onClick={onClickHandler}
                className="absolute bottom-1 right-4 transform -translate-y-1/2 bg-logo text-white h-[40px] w-[40px] rounded-full shadow-md hover:bg-opacity-80"
                style={{ zIndex: 10 }}
            >
                &#8594; {/* You can replace this with an icon */}
            </button>
        )
    );

    React.useEffect(() => {
        dispatch(storeQRData(rid, tid))
        dispatch(Init())
        dispatch(getRestaurant(rid, setLoading, setProgress))
        const setFp = async () => {
            const fp = await FingerprintJS.load()
            const { visitorId } = await fp.get()
            dispatch(startSession(tid, visitorId, setConnected))
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
        if (session_id === null && user_id === null) {
            setFp()
        }
        else {
            setConnected(true)
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
    }, [dispatch, location])

    return (
        <>
            <TopLoadingBar
                color="#f414e4"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            {/* Section 1 */}
            {
                !loading ?
                    (
                        <>

                            <div
                                style={{
                                    backgroundImage: `url(${data?.restaurant_images?.HERO[0]})`,
                                    backgroundSize: 'cover'
                                }}
                                role="banner"
                                aria-label={`Hero section for ${data?.name || 'Restaurant'}`}
                                className={`w-full h-[585px] bg-black/20`}
                            >
                                <div className="absolute inset-0 h-[585px]  bg-black/50"></div>
                                <div className="relative h-full w-full flex flex-col justify-center items-center">

                                    <img
                                        src={data?.image}
                                        alt="icon"
                                        className="h-[100px] w-[100px] rounded-full relative  object-contain shadow-md self-center"
                                    />
                                    <h1 className="font-heading capitalize w-[88%] text-white font-semibold text-4xl text-center">
                                        {data?.tag_line}
                                    </h1>
                                    <div className="w-full flex justify-around items-center py-4 bg-white fixed bottom-0 z-50 lowercase">
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `/menu/?restaurant_id=${rid}&restaurant_name=${data?.name}&table_id=${table_id}`
                                                )
                                            }}
                                            className="bg-logo text-white font-medium h-[40px] w-[116px] rounded-lg text-base mt-2 font-body lowercase outline-none transition-all hover:bg-logo-dark"
                                        >
                                            View Menu
                                        </button>

                                        <button
                                            onClick={() => {
                                                navigate(
                                                    session_id !== null && user_id !== null
                                                        ? '/pay-bill'
                                                        : `/empty-cart/?restaurant_id=${rid}&restaurant_name=${data?.name}&table_id=${table_id}`
                                                );
                                            }}
                                            className="bg-white text-logo border border-logo font-medium h-[40px] w-[116px] rounded-lg text-base mt-2 font-body lowercase outline-none transition-all hover:bg-logo hover:text-white"
                                        >
                                            Pay Bill
                                        </button>
                                    </div>

                                </div>

                            </div>
                            {/* Section 2 */}
                            <div
                                className=" w-full h-auto object-contain py-3 flex flex-col justify-center items-center"
                            >
                                <h1 className=" font-body text-black font-bold text-3xl text-center">
                                    {data?.name}
                                </h1>
                                <p className="mt-5 font-body font-light capitalize px-3 text-black text-justify">
                                    {data?.description}
                                </p>
                                {/* <button
                                    onClick={() => {
                                        navigate(
                                            `/menu/?restaurant_id=${rid}&restaurant_name=${data?.name}&table_id=${table_id}`
                                        )
                                    }}
                                    className=" bg-logo outline-none mt-2 self-center font-medium  h-[40px] w-[116px] rounded-lg text-base font-body text-white"
                                >
                                    View Menu
                                </button> */}
                            </div>
                            {/* Section 3 */}
                            <div className=" w-[100vw] object-contain py-5  flex flex-col justify-center items-center">
                                <h1 className=" font-body capitalize text-lg pb-5 font-bold text-black text-start w-full px-3">
                                    restaurant Images
                                </h1>
                                {data?.restaurant_images?.SLIDER?.length > 0 && (
                                    <Carousel
                                        autoPlay
                                        infiniteLoop
                                        showThumbs={false}
                                        showIndicators={true}
                                        showArrows={true}
                                        interval={5000}
                                        renderArrowNext={(onClickHandler, hasNext) =>
                                            customArrowRight(onClickHandler, hasNext)
                                        }
                                    >
                                        {data?.restaurant_images?.SLIDER?.map((image, index) => (
                                            <img
                                                key={index}
                                                alt="Restaurant"
                                                src={image}
                                                className="w-full h-[300px] object-cover"
                                                loading="lazy"
                                            />
                                        ))}
                                    </Carousel>)}
                            </div>
                            {/* Section 4 */}
                            <div className=" w-[100vw] object-contain  flex flex-col justify-center items-center">
                                <h1 className=" font-body capitalize text-lg pb-2 font-bold text-black text-start w-full px-3">
                                    restaurant Socials
                                </h1>
                                <div className="flex space-x-3 pb-4">
                                    <AiFillFacebook size={35} onClick={() => {
                                        window.open(data?.restaurant_socials?.FACEBOOK, '_blank')
                                    }} />
                                    <AiFillInstagram size={35}
                                        onClick={() => {
                                            window.open(data?.restaurant_socials?.INSTAGRAM, '_blank')
                                        }}
                                    />
                                    <AiFillTwitterCircle size={35}
                                        onClick={() => {
                                            window.open(data?.restaurant_socials?.TWITTER, '_blank')
                                        }}
                                    />
                                </div>
                            </div>
                            <footer className="bg-black text-white py-4 pb-28">
                                <div className="container mx-auto px-4">
                                    {/* Footer Content */}
                                    <div className="flex flex-wrap justify-between items-center">
                                        {/* About Saturday */}
                                        <div className="mb-4 md:mb-0">
                                            <h2 className="font-body font-semibold text-lg">Powered by <span className="text-logo">SaturdayApp</span> </h2>
                                            <p className="font-body text-sm text-gray-400 mt-2">
                                                Revolutionizing dining with our QR menu ordering system. Enjoy seamless dining experiences at your favorite restaurants.
                                            </p>
                                        </div>

                                        {/* Quick Links */}
                                        <div className="mb-4 md:mb-0">
                                            <h2 className="font-body font-semibold text-lg">Quick Links</h2>
                                            <ul className="mt-2 space-y-1">
                                                <li>
                                                    <button
                                                        className="text-gray-400 hover:text-white"
                                                        onClick={() =>
                                                            window.open(
                                                                'https://www.saturdayapp.in/who-we-are',
                                                                '_blank'
                                                            )
                                                        }
                                                    >
                                                        About Saturday
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="text-gray-400 hover:text-white"
                                                        onClick={() =>
                                                            window.open(
                                                                'https://www.saturdayapp.in/privacy-policy',
                                                                '_blank'
                                                            )
                                                        }
                                                    >
                                                        Privacy Policy
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="text-gray-400 hover:text-white"
                                                        onClick={() =>
                                                            window.open(
                                                                'https://www.saturdayapp.in/terms-and-condition',
                                                                '_blank'
                                                            )
                                                        }
                                                    >
                                                        Terms & Condition
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        {/* Partner Acknowledgment */}
                                        <div className="mb-4 md:mb-0 self-start">
                                            <h2 className="font-body font-semibold text-lg">Partnered With</h2>
                                            <p className="font-body text-sm text-gray-400 mt-2">
                                                {data?.name || "Your Restaurant Name"}
                                            </p>
                                            <p className="font-body text-sm text-gray-400">{data?.address}</p>
                                        </div>

                                        {/* Social Media */}
                                        <div>
                                            <h2 className="font-body font-semibold text-lg">Follow Saturday</h2>
                                            <div className="flex space-x-3 mt-2">
                                                <AiFillFacebook
                                                    size={28}
                                                    className="cursor-pointer hover:text-blue-500"
                                                    onClick={() => window.open('https://www.facebook.com/saturdayapp.in', '_blank')}
                                                />
                                                <AiFillInstagram
                                                    size={28}
                                                    className="cursor-pointer hover:text-pink-500"
                                                    onClick={() => window.open('https://www.instagram.com/saturdayapp.in/', '_blank')}
                                                />
                                                <AiFillLinkedin
                                                    size={28}
                                                    className="cursor-pointer hover:text-blue-400"
                                                    onClick={() => window.open('https://www.linkedin.com/company/saturdayapp/', '_blank')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Bottom Line */}
                                    <div className="text-center text-sm text-gray-500 mt-4">
                                        © {new Date().getFullYear()} SaturdayApp LLC. All Rights Reserved.
                                    </div>
                                </div>
                            </footer>
                        </>
                    ) :
                    <div className="flex h-screen w-screen justify-center items-center space-x-2">
                        <img src={image.logo} className='w-12 h-11' alt='logo' />
                        <p className="text-4xl font-light font-body">Saturday</p>
                    </div>
            }


        </>
    )
}
