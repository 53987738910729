import React from 'react';
import { Helmet } from 'react-helmet';
import { TypeAnimation } from 'react-type-animation';
import Header from '../../component/Header';
import Footer from '../../component/Footer';

export default function PrivacyPolicy() {
    return (
        <>
            {/* SEO Optimized Head Tags */}
            <Helmet>
                <title>Privacy Policy | Saturday App</title>
                <meta
                    name="description"
                    content="Learn how Saturday App collects, uses, and protects your personal data. Read our Privacy Policy for transparency and clarity."
                />
                <meta name="keywords" content="Privacy Policy, Saturday App, Data Protection, User Privacy" />
                <meta name="author" content="Saturday App" />
                <meta property="og:title" content="Privacy Policy | Saturday App" />
                <meta
                    property="og:description"
                    content="Learn how Saturday App collects, uses, and protects your personal data. Read our Privacy Policy for transparency and clarity."
                />
                <meta property="og:url" content="https://www.saturdayapp.in/privacy-policy" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header screen={true} />
            {/* Privacy Policy Page Content */}
            <main className="flex flex-col justify-start items-center py-10 font-body text-black h-auto w-screen gap-20">
                <section className="text-center pt-10">
                    {/* Animated Heading */}
                    <TypeAnimation
                        sequence={["User Privacy Policy", 1000]}
                        speed={50}
                        cursor={false}
                        style={{ fontSize: '50px', fontWeight: 'normal' }}
                        repeat={Infinity}
                    />
                </section>

                <section className="w-[88%] md:w-[50vw] flex flex-col gap-5 text-lg font-normal text-gray-800">
                    <p className="font-semibold">Last Updated: 17 December 2024</p>

                    <p>
                        Your privacy is of the utmost importance to us. This User Privacy Policy (“Privacy Policy”) sets out how Saturday App, LLC. and our subsidiaries and affiliates (together “we” or “Saturday”) collect, receive, use, and disclose information about you when you access or use our website. <a className=' text-logo underline' href='https://www.saturdayapp.in'>https://www.saturdayapp.in</a> , any Saturday application (collectively the “Saturday App”), and other Saturday products and services (collectively the “Saturday Solution”), including while ordering and receiving catering or hospitality services from a merchant that uses the Saturday Solution (“Saturday Merchants” or “Merchants”) and when you engage with us or otherwise interact with Saturday.
                    </p>

                    {/* Section 1: Information We Collect */}
                    <h2 className="text-2xl font-semibold mt-4">1. Information We Collect</h2>
                    <div>
                        <h3 className="text-xl font-semibold">1.1 Personal Information</h3>
                        <ul className="list-disc pl-5">
                            <li>Name</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Billing and shipping addresses</li>
                            <li>Payment details</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold">1.2 Business Information</h3>
                        <ul className="list-disc pl-5">
                            <li>Restaurant name</li>
                            <li>Restaurant address</li>
                            <li>Point-of-sale (POS) system information</li>
                            <li>Transaction details</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold">1.3 Usage Data</h3>
                        <ul className="list-disc pl-5">
                            <li>IP address</li>
                            <li>Device information</li>
                            <li>Browser type</li>
                            <li>Pages visited and time spent</li>
                        </ul>
                    </div>

                    {/* Section 2: How We Use Your Information */}
                    <h2 className="text-2xl font-semibold mt-4">2. How We Use Your Information</h2>
                    <div>
                        <h3 className="text-xl font-semibold">2.1 To Provide and Improve Services</h3>
                        <ul className="list-disc pl-5">
                            <li>Process payments and transactions</li>
                            <li>Provide customer support</li>
                            <li>Personalize your experience</li>
                            <li>Enhance our services</li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-xl font-semibold">2.2 Marketing Communications</h3>
                        <p>
                            We may use your information to send promotional content. You can opt out at any time by clicking the
                            unsubscribe link or contacting us.
                        </p>
                    </div>

                    <div>
                        <h3 className="text-xl font-semibold">2.3 Legal Compliance</h3>
                        <p>We use your data to comply with laws, enforce terms, and resolve disputes.</p>
                    </div>

                    {/* Section 3: Data Protection */}
                    <h2 className="text-2xl font-semibold mt-4">3. How We Protect Your Information</h2>
                    <ul className="list-disc pl-5">
                        <li>Data Encryption: Industry-standard encryption secures your data.</li>
                        <li>Access Control: Only authorized personnel can access your data.</li>
                        <li>Third-Party Services: We partner with trusted providers for payments and hosting.</li>
                    </ul>

                    {/* Section 4: Sharing Your Information */}
                    <h2 className="text-2xl font-semibold mt-4">4. Sharing Your Information</h2>
                    <ul className="list-disc pl-5">
                        <li>Third-Party Providers: We share data with trusted vendors.</li>
                        <li>Legal Compliance: Data may be shared if required by law.</li>
                        <li>Business Transfers: Data may be transferred during mergers or acquisitions.</li>
                    </ul>

                    {/* Section 5: Cookies and Tracking */}
                    <h2 className="text-2xl font-semibold mt-4">5. Cookies and Tracking</h2>
                    <p>We use cookies and similar technologies to enhance user experience, analyze usage, and deliver tailored content.</p>

                    {/* Section 6: Third-Party Links */}
                    <h2 className="text-2xl font-semibold mt-4">6. Third-Party Links</h2>
                    <p>Our services may contain links to third-party sites. We are not responsible for their privacy practices.</p>

                    {/* Section 7: Your Rights */}
                    <h2 className="text-2xl font-semibold mt-4">7. Your Rights</h2>
                    <ul className="list-disc pl-5">
                        <li>Access: Request access to your personal data.</li>
                        <li>Correction: Request corrections to inaccurate data.</li>
                        <li>Deletion: Request deletion of your personal data.</li>
                        <li>Opt-Out: Opt-out of marketing communications.</li>
                    </ul>

                    {/* Section 8: Changes to This Policy */}
                    <h2 className="text-2xl font-semibold mt-4">8. Changes to This Policy</h2>
                    <p>We may update this Privacy Policy from time to time. Changes will be reflected on this page.</p>

                    {/* Section 9: Contact Section */}
                    <h2 className="text-2xl font-semibold mt-4">9. Contact Us</h2>
                    <p>If you have questions or concerns about our Privacy Policy, contact us:</p>
                    <ul className="list-none pl-0">
                        <li>Email: <a href="mailto:support@saturdayapp.in" className="text-blue-600">support@saturdayapp.in</a></li>
                        <li>Phone: <a href="tel:02534508891" className="text-blue-600">02534508891</a></li>
                        <li>Address: KBT Circle, Office No. 8, Mezzanine 1st Floor, Jagdish Sankul, Nashik, Maharashtra 422005</li>
                    </ul>

                    <p>By using our services, you agree to this Privacy Policy.</p>
                </section>
            </main>
            <Footer screen={true} />
        </>
    );
}
