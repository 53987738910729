import React from 'react';
import { IoChevronBack } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { image } from '../../assets/image';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { createOrder, createPayment, startSession, verifyPayment } from '../../store/actions/sessionAction';
import { toast } from 'react-toastify';
import { HiOutlineCheck } from 'react-icons/hi';
import { TailSpin } from 'react-loader-spinner';
import { TypeAnimation } from 'react-type-animation';

export default function Checkout() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { state } = useLocation()

    const cart = useSelector((state) => state.Reducers.cart);
    const [selectedPayment, setSelectedPayment] = React.useState(0)
    const [loading, setLoading] = React.useState(true)
    const [loading1, setLoading1] = React.useState(false)
    const [orderLoad, setOrderLoad] = React.useState(false)
    const [connected, setConnected] = React.useState(false)
    const tid = useSelector((state) => state.Reducers.table_id)
    const session_id = useSelector((state) => state.Reducers.session_id)
    const user_id = useSelector((state) => state.Reducers.user_id)

    const paymentMethods = [
        {
            id: 1,
            name: 'Cash',
            icon: image.cash,
            description: 'Pay with cash at the time of delivery or pickup.'
        },
        {
            id: 2,
            name: 'Card',
            icon: image.card,
            description: 'Pay securely with your debit or credit card.'
        },
        {
            id: 3,
            name: 'UPI',
            icon: image.upi, // You can use a UPI-related logo/icon
            description: 'Pay easily using UPI apps like Google Pay, PhonePe, etc.'
        }
    ];

    function getData() {
        let result = {};

        cart.forEach((item, index) => {
            result[item?.id] = {
                quantity: item.qty,
                customizations: item.customizations || {},
                notes: item.notes || ""
            };
        });
        return JSON.stringify(result) // Return the data as a JSON string
    }

    const total = cart
        .map(item => item.price * item.qty)  // Calculate total for each item
        .reduce((acc, current) => acc + current, 0);  // Sum up all the prices

    React.useEffect(() => {
        const setFp = async () => {
            const fp = await FingerprintJS.load()
            const { visitorId } = await fp.get()
            dispatch(startSession(tid, visitorId, setConnected))
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
        if (session_id === null && user_id === null) {
            setFp()
        }
        else {
            setConnected(true)
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
    }, [dispatch])
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };
    const handlePayment = async (id) => {
        if (!id) {
            console.error("Order ID is missing!");
            return;
        }

        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const options = {
            key: "rzp_test_JMJraiRiPhLJCw",
            amount: (state?.amount + state?.amount * 0.02).toFixed() * 100,
            currency: "INR",
            order_id: id,
            handler: (response) => {
                console.log("Payment Response:", response);
                dispatch(verifyPayment(response, (state?.amount + state?.amount * 0.02).toFixed() * 100, setLoading1, navigate))
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };


    return (
        <>
            {
                loading ?
                    <div className="flex justify-center h-screen w-screen flex-col font-body space-y-4 items-center">
                        <TailSpin
                            visible={true}
                            height="30"
                            width="30"
                            // radius="9"
                            color="#f414e4"
                            ariaLabel="three-dots-loading"
                        />
                        {
                            loading1 ?
                                <p className="text-xs lowercase text-logo font-semibold">
                                    Verifying Payment
                                </p>
                                :
                                <p className="text-xs lowercase text-logo font-semibold">
                                    Establishing secure connection
                                </p>
                        }
                    </div>
                    :
                    <div className="bg-white w-full h-[100vh] font-body flex flex-col">

                        {/* Header Section */}
                        <div className="flex justify-start top-0 sticky z-50 left-0 px-3 py-3 items-center w-full bg-white">
                            <button
                                className="h-[35px] w-[35px] flex justify-center items-center text-logo bg-transparent rounded-full"
                                onClick={() => navigate(-1)}
                            >
                                <IoChevronBack className="text-3xl" />
                            </button>
                            <h1 className="text-black flex ml-3 lowercase items-center text-center cursor-pointer text-2xl sm:text-3xl md:text-4xl font-body font-light">
                                Checkout
                            </h1>
                        </div>
                        <div className="flex flex-col gap-3 pt-4 w-[90%] self-center">

                            {
                                state?.state ?
                                    <>
                                        <h1 className="text-black flex  lowercase items-center text-center cursor-pointer text-base  font-body font-light">
                                            Payment mode
                                        </h1>
                                        {paymentMethods.map((data, index) => (
                                            <label
                                                key={index}
                                                className={`flex items-center justify-between gap-4 border-[1px] rounded px-6 h-14  cursor-pointer transition-all duration-300 ease-in-out 
                    ${selectedPayment === index
                                                        ? 'border-black shadow-lg transform'
                                                        : 'border-gray-300 bg-white'} 
                    hover:bg-gray-50`}
                                            >
                                                <input
                                                    id={index}
                                                    type="radio"
                                                    name="payment"
                                                    value={data.name}
                                                    className="hidden"
                                                    checked={selectedPayment === index}
                                                    onChange={() => setSelectedPayment(index)}
                                                />

                                                {/* Custom Radio Button */}
                                                <div className="flex space-x-3">
                                                    <div
                                                        className={` w-[20px] h-[20px] rounded-full bg-black justify-center items-center flex transition-all duration-300 ease-in-out 
                                ${selectedPayment === index ? '' : 'bg-transparent border border-black'}`}
                                                    >
                                                        {selectedPayment === index ? <HiOutlineCheck className='text-white text-base' /> : null}
                                                    </div>


                                                    <div className="flex flex-col justify-center items-start">
                                                        <p className="font-light text-base text-gray-800">{data.name}</p>
                                                    </div>
                                                </div>
                                                <div className=" w-[40px] h-[40px] flex items-center justify-center  rounded-full p-2 transition-all duration-300">

                                                    <div className={`w-[35px] h-[35px] flex items-center justify-center`}>
                                                        <img
                                                            src={data.icon}
                                                            className="w-[30px] h-[30px] object-contain"
                                                            alt={data.name}
                                                        />
                                                    </div>
                                                </div>
                                            </label>


                                        ))}
                                    </>
                                    :
                                    null
                            }

                            {/* <hr className='w-[100%] pt-10' /> */}
                            <div className="flex w-full flex-col items-center">
                                {
                                    state?.state ?
                                        <div className="flex w-full justify-between text-gray-500">
                                            <div className="space-y-2 w-full flex font-normal flex-col justify-start">
                                                <p>Subtotal</p>
                                                {
                                                    selectedPayment === 2 ?
                                                        <p>Platform Fees</p>
                                                        :
                                                        null
                                                }

                                                <p className="text-black font-medium">Total</p>
                                            </div>
                                            <div className="space-y-2 w-full flex flex-col justify-end items-end text-gray-500 font-normal">
                                                <p>{state?.currency} {state?.amount}</p>
                                                {
                                                    selectedPayment === 2 ?
                                                        <>
                                                            <p>{state?.currency} {(state?.amount * 0.02).toFixed(2)}</p>
                                                            <p className='text-black font-medium'>{state?.currency} {(state?.amount + state?.amount * 0.02).toFixed()}</p>
                                                        </>
                                                        :
                                                        <p className='text-black font-medium'>{state?.currency} {(state?.amount).toFixed()}</p>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <TypeAnimation
                                                sequence={[
                                                    " eat first, pay later", 1000,   // First text, with a 1-second delay
                                                ]}
                                                speed={50}  // Typing speed
                                                cursor={false}  // No cursor
                                                style={{ fontSize: '18px', fontWeight: 'normal' }}  // Custom styling
                                                repeat={Infinity}  // Infinite looping of the sequence
                                            />
                                            <div className="flex w-full justify-between text-gray-500">
                                                <div className="space-y-2 w-full flex font-normal flex-col justify-start">
                                                    <p>Subtotal</p>
                                                    {/* <p>Digital Fees</p> */}
                                                    <p className="text-black font-medium">Total</p>
                                                </div>
                                                <div className="space-y-2 w-full flex flex-col justify-end items-end text-gray-500 font-normal">
                                                    <p>{cart[0]?.currency || state?.currency} {total || state?.amount}</p>
                                                    {/* <p>{cart[0]?.currency || state?.currency} {(total * 0.01).toFixed(2) || (state?.amount * 0.01).toFixed(2)}</p> */}
                                                    <p className='text-black font-medium'>{cart[0]?.currency} {(total).toFixed()}</p>
                                                </div>
                                            </div>
                                        </>
                                }

                                {
                                    state?.state ?
                                        <button
                                            type="button"
                                            disabled={orderLoad}
                                            onClick={() => {
                                                if (connected) {
                                                    dispatch(createPayment(session_id, user_id, state?.method, state?.amount, setLoading, navigate, handlePayment))
                                                }
                                                else {
                                                    toast.error("Oops! We couldn't connect to your Saturday session. Please try again later.")
                                                }
                                            }}
                                            className="bg-black h-14 mt-8 w-full text-white lowercase flex justify-center items-center text-md md:text-base rounded-full  transition"
                                        >
                                            {
                                                orderLoad ?
                                                    <TailSpin
                                                        visible={true}
                                                        height="=20"
                                                        width="20"
                                                        color="#fff"
                                                        ariaLabel="three-dots-loading"
                                                    />
                                                    :
                                                    `pay with ${paymentMethods[selectedPayment]?.name}`
                                            }
                                        </button>
                                        :
                                        <button
                                            type="button"
                                            disabled={orderLoad}
                                            onClick={() => {
                                                if (connected) {
                                                    dispatch(createOrder(
                                                        {
                                                            session_id: session_id,
                                                            session_user_id: user_id,
                                                            items_data: getData()
                                                        }, navigate, setOrderLoad))
                                                }
                                                else {
                                                    toast.error("Oops! We couldn't connect to your Saturday session. Please try again later.")
                                                }
                                            }}
                                            className="bg-black h-14 mt-8 w-full text-white lowercase flex justify-center items-center text-md md:text-base rounded-full  transition"
                                        >
                                            {
                                                orderLoad ?
                                                    <TailSpin
                                                        visible={true}
                                                        height="=20"
                                                        width="20"
                                                        color="#fff"
                                                        ariaLabel="three-dots-loading"
                                                    />
                                                    :
                                                    "order now"
                                            }
                                        </button>
                                }

                                <div className="flex flex-col items-center py-4 justify-center">
                                    <p className="flex space-x-2 pb-5">
                                        <span className=' lowercase'>Pay secure with</span>
                                        <img src={image.logo} className="w-6 h-6" />
                                        <span className="font-semibold">
                                            saturday
                                        </span>
                                    </p>
                                    <p className=' text-[10px] text-center '>
                                        By using the saturday app, you agree to our <span onClick={() => {
                                            window.open("/terms-and-condition")
                                        }} className='text-logo underline cursor-pointer'>terms of use</span>  and to the processing of your personal data in accordance with our<span onClick={() => {
                                            window.open("/privacy-policy")
                                        }} className='text-logo underline cursor-pointer'>privacy policy</span> . Your information is collected on behalf of the restaurant and will be shared with the restaurant.
                                    </p>
                                </div>
                                {connected ? (
                                    <div className="flex self-center items-center font-heading justify-start space-x-3 ">
                                        <div className="h-[12px] w-[12px] rounded-full  bg-green-500"></div>
                                        <p className=" text-xs">Session Connected</p>
                                    </div>
                                ) : (
                                    <div className="flex self-center items-center justify-start space-x-3 ">
                                        <div className="h-[12px] w-[12px] rounded-full  bg-red-500"></div>
                                        <p className=" text-xs">Session Error</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
            }
        </>

    );
}
